import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';
import { Check } from '@phosphor-icons/react';
import { useSidebar } from '../contexts/sidebar';
import { useSmallScreenAndBelowMediaQuery } from '../layouts/ResponsiveMedia';

export const HeaderText = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  white-space: nowrap;
`;

export const PageTitle = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  white-space: nowrap;
`;

export const MuteText = styled.span`
  color: ${({ theme }) => theme.colors.grey};
`;

export const ColorText = styled.span`
  color: ${({ color }) => color || 'black'};
`;

export const PrimaryText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

export const DangerText = styled.span`
  color: ${({ theme }) => theme.colors.danger};
`;

export const YellowText = styled.span`
  color: ${({ theme }) => theme.colors.yellow};
`;

export const SuccessText = styled.span`
  color: ${({ theme }) => theme.colors.success};
`;

export const ActiveText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

export const AccentText = styled.span`
  color: ${({ theme }) => theme.colors.accent};
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const Border = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.primary} !important;
`;

export const ShadowBox = styled.div`
  background: ${({ theme }) => theme.colors.white} !important;
  box-shadow: 0 2px 15px rgba(57, 57, 72, 0.05);
  border-radius: 5px;
  padding: 1.3em;
`;

export const BodyContainer = styled.div`
  padding: 15px 20px;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CommonStyleHeadeBar = styled.div`
  border-bottom: 0.5px solid;
  border-color: ${({ theme }) => theme.colors.grey};
  position: fixed;
  top: 0;
  z-index: ${({ zIndex }) => zIndex || '997'};
  transition: all 0.2s;
  background: ${({ theme }) => theme.colors.box};
  height: 67px;
`;

const StyledHeaderBar = styled(CommonStyleHeadeBar)`
  padding: 14px 5px;
  width: calc(100% - ${({ isCollapsed }) => (isCollapsed ? '70px' : '255px')});
  left: ${({ isCollapsed }) => (isCollapsed ? '60px' : '245px')};
  top: 10px;
  border-radius: 10px 10px 0 0;
  &:row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  &.tab-layout {
    border-bottom: 0px;
  }

  &.no-sidebar {
    top: 0px;
    width: 100%;
    left: 0px;
    padding: 14px 100px;
  }
`;

export function HeaderBar({
  children,
  className,
  zIndex,
  darkBackground,
  ...rest
}) {
  const { isCollapsed } = useSidebar();
  const isTabletAndBelow = useSmallScreenAndBelowMediaQuery();

  return (
    <StyledHeaderBar
      darkBackground={darkBackground}
      zIndex={zIndex}
      className={className}
      isCollapsed={isCollapsed || isTabletAndBelow}
      {...rest}
    >
      {children}
    </StyledHeaderBar>
  );
}

const StyledSlider = styled(Checkbox)`
  &&& {
    input ~ label::before {
      background-color: ${({ theme }) => theme.colors.green} !important;
    }
    input:checked ~ label::before {
      background-color: ${({ theme }) => theme.colors.green} !important;
    }
    input:focus:checked ~ label::before {
      background-color: ${({ theme }) => theme.colors.green} !important;
    }
  }
`;

export function StyledSliderCheckbox({ ...props }) {
  return <StyledSlider toggle {...props} />;
}

const ChecboxHolder = styled.div`
  position: relative;
  display: flex;
`;

const PrimaryCheckbox = styled(Checkbox)`
  label:before,
  label:after {
    width: ${({ width }) => width ?? '14px'} !important;
    height: ${({ height }) => height ?? '14px'} !important;
  }

  &.large-checkbox > label:before,
  label:after {
    width: ${({ width }) => width ?? '19px'} !important;
    height: ${({ height }) => height ?? '19px'} !important;
  }
  &.large-checkbox {
    &:before {
      display: none !important;
    }
  }

  &&& {
    input:checked ~ label::before {
      outline: none;
      border-radius: ${({ rounded }) => (rounded ? '50%' : '')};
      background: ${({ theme, bgColor }) => bgColor || theme.colors.primary};
      border-color: ${({ theme, bgColor }) => bgColor || theme.colors.primary};
    }
    input:checked:focus ~ label::before {
      border-color: ${({ theme, bgColor }) =>
        bgColor || theme.colors.primary} !important;
    }
    label:before,
    label:after {
      content: '' !important;
      border-radius: ${({ rounded }) => (rounded ? '50%' : '')};
    }
  }

  &.disabled {
    pointer-events: none;
  }
  &:hover {
    &:before {
      width: 42px;
      height: 42px;
      left: -14px;
      top: -14px;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 1px;
    left: 0px;
    top: 0px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.05);
    position: absolute;
    transition: all 0.1s ease;
  }
`;

const ChecboxIcon = styled(Check)`
  position: absolute;
  pointer-events: none;
  top: ${({ top }) => top ?? '1px'};
  left: ${({ left }) => left ?? '1px'};
  z-index: 0;
`;

export function StyledCheckbox({ ...props }) {
  return (
    <ChecboxHolder>
      <PrimaryCheckbox {...props} />
      <ChecboxIcon
        size={12}
        className="checkbox-icon"
        color="white"
        weight="bold"
      />
    </ChecboxHolder>
  );
}
