import { useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { tooltipTexts } from '../../../../utility/constants';
import { AppTable, TableHeaderToolTip } from '../../../../components';
import { FulfillOrderPopup, OrderRow } from '../../../../page-components';
import { CommonUtility, OrderService, ToastMessage } from '../../../../utility';

export function OrderListTab({
  refresh,
  filter: tableFilter,
  filterChanged,
  loading: orderLoading,
  data: orderData,
  totalData,
  totalPages,
  pageChanged,
  parsedSort,
  onSortChange,
}) {
  const { pathname } = useLocation();

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 4);
    }
    return null;
  }, [pathname]);

  const [loading, setLoading] = useState(false);

  const [orderToFulfill, setOrderToFulfill] = useState(null);

  const orderColumns = [
    {
      text: 'Order ID',
      key: 'order_id',
      textAlign: 'left',
      width: 3,
      component: (
        <TableHeaderToolTip
          label="Order ID"
          helpText={tooltipTexts.orderId}
          noInfoRightPadding
          wrapperClass="d-flex align-items-center"
        />
      ),
    },
    {
      text: 'Age',
      key: 'order_date',
      textAlign: 'left',
      sortKey: 'timeAcknowledged',
      width: 3,

      component: (
        <TableHeaderToolTip
          label="Age"
          helpText={tooltipTexts.orderAge}
          noInfoRightPadding
          wrapperClass="d-flex align-items-center float-left"
        />
      ),
    },
    {
      text: 'Warehouse',
      key: 'warehouse',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Organization',
      key: 'organization',
      width: 3,
      textAlign: 'left',

      component: (
        <TableHeaderToolTip
          label="Organization"
          helpText={tooltipTexts.organization}
          noInfoRightPadding
        />
      ),
    },

    {
      text: 'Items',
      key: 'total_items',
      width: 3,

      component: (
        <TableHeaderToolTip
          label="Items"
          helpText={tooltipTexts.totalItems}
          noInfoRightPadding
        />
      ),
    },
  ];

  const fulfillOrder = async () => {
    try {
      setLoading(true);
      const payload = {
        action: 'fulfill',
      };

      await OrderService.orderAction(orderToFulfill, payload);
      closePopup();
      ToastMessage.success('Order fulfilled successfully.');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const openPopup = order => {
    setOrderToFulfill(order._id);
  };

  const closePopup = () => {
    setOrderToFulfill(null);
  };

  return (
    <div>
      <AppTable
        columns={orderColumns}
        loading={orderLoading}
        RowComponent={
          <OrderRow
            showAction={currentTab === 'pending-fulfillment'}
            openPopup={openPopup}
          />
        }
        headerOffSet={32}
        data={orderData}
        totalData={totalData}
        totalPages={totalPages}
        pageChanged={pageChanged}
        sort={parsedSort}
        currentPage={tableFilter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={tableFilter.limit}
        sortChanged={sort => onSortChange(sort)}
      />
      <FulfillOrderPopup
        open={!!orderToFulfill}
        onClose={closePopup}
        onConfirm={fulfillOrder}
        loading={loading}
      />
    </div>
  );
}
