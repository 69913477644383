import { Loader, TableCell, TableRow } from 'semantic-ui-react';
import {
  Check,
  GridNine,
  RectangleDashed,
  StackSimple,
  Tray,
} from '@phosphor-icons/react';
import { CommonUtility, DateFormat, DateUtility, theme } from '../../utility';
import { HoverBorderButton, MuteText } from '../../elements';
import { ItemImage } from '../../components';

export function WaveItemRow({
  item,
  markItemPicked,
  itemMarkedTracker,
  isWaveInBuilding,
  isWaveInSorting,
  markItemAsAllocated,
}) {
  function Actions() {
    if (itemMarkedTracker[item.id] === 'loading') {
      return <Loader active inline size="tiny" />;
    }

    if (itemMarkedTracker[item.id] === 'pending' && !isWaveInBuilding) {
      return (
        <div className="d-flex justify-content-center">
          <HoverBorderButton
            onClick={() => markItemPicked(item)}
            className="flex-btn"
          >
            <Check className="mr-1" size={16} />
            Mark Picked
          </HoverBorderButton>
        </div>
      );
    }

    if (itemMarkedTracker[item.id] === 'picked') {
      if (isWaveInSorting) {
        return (
          <div className="d-flex justify-content-center">
            <HoverBorderButton
              onClick={() => markItemAsAllocated(item)}
              className="flex-btn"
            >
              <StackSimple className="mr-1" size={16} />
              Sort
            </HoverBorderButton>
          </div>
        );
      }

      return (
        <div className="d-flex justify-content-center">
          <Check size={17} color={theme.colors.green} />
          <span className="ml-2">Picked</span>
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center justify-content-center">
        {isWaveInBuilding && (
          <Loader active indeterminate inline size="tiny" className="mr-2" />
        )}
        {CommonUtility.removeUnderscoreAndCapitalize(
          itemMarkedTracker[item.id],
        )}
      </div>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <div className="d-flex align-items-start">
          <ItemImage
            item={item.inventory.product}
            imageKey="images"
            displayImageKey="display_image"
          />
          <div className="d-flex flex-column ml-4">
            <span>{item.inventory?.product?.name}</span>
            <div className="d-flex mt-1">
              <MuteText className="mr-2">SKU</MuteText>
              <span>{item.inventory?.product?.sku}</span>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {DateUtility.formatDate(item.allocatedAt, DateFormat.dateTime)}
      </TableCell>
      <TableCell>
        <div className="d-flex">
          <RectangleDashed size={17} />
          <span className="ml-2">{item?.inventory?.zone?.code}</span>
        </div>
      </TableCell>
      <TableCell>
        <div className="d-flex">
          <GridNine size={17} />
          <span className="ml-2">{item?.inventory?.rack?.code}</span>
        </div>
      </TableCell>
      <TableCell>
        <div className="d-flex">
          <Tray size={17} />
          <span className="ml-2">{item?.inventory?.location?.code}</span>
        </div>
      </TableCell>
      <TableCell textAlign="center">{item?.quantity}</TableCell>
      <TableCell textAlign="center">
        <Actions />
      </TableCell>
    </TableRow>
  );
}
