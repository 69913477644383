import { CaretDown, CaretRight } from '@phosphor-icons/react';
import { MuteText } from '../../../elements';

export function ExpandCollapseToggle({
  open,
  setOpen,
  title = 'Multiple Aliases',
  className = '',
  expandText = 'Expand',
  collapseText = 'Collapse',
}) {
  return (
    <div
      className={`d-flex flex-column align-items-start justify-content-center ${className}`}
    >
      <MuteText>{title}</MuteText>
      <div
        onClick={() => setOpen(!open)}
        className="d-flex align-items-center cursor-pointer"
      >
        {open ? (
          <>
            <b>{collapseText}</b>
            <CaretDown className="ml-1" />
          </>
        ) : (
          <>
            <b>{expandText}</b>
            <CaretRight className="ml-1" />
          </>
        )}
      </div>
    </div>
  );
}
