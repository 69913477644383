import { Package, ShippingContainer, Truck } from '@phosphor-icons/react';
import { Images } from '../../images';

export const CommonConstant = {
  user: '3pl-user',
  environment: 'environment',
  currentRegion: 'currentRegion',
  defaultImage: Images.default,
  metadata: 'metadata',
  defaultPageSize: 10,
  noPage: 'no-page',
  noTab: 'no-tab',
  permissionMeta: 'permission-meta',
  pageSizes: [
    { key: 10, value: 10, text: '10 results per page' },
    { key: 25, value: 25, text: '25 results per page' },
    { key: 50, value: 50, text: '50 results per page' },
    { key: 100, value: 100, text: '100 results per page' },
  ],
  sortDirection: {
    ascending: 'asc',
    descending: 'desc',
  },
  loggedInTime: 'loggedInTime',
  tableFilter: 'tableFilter',
};

export const ErrorMessageConstant = {
  defaultAPIError: 'Something went wrong, please contact administrator',
};

export const OrderUrlKeys = {
  'pending-shipment': 'Pending Shipment',
  'pending-fulfillment': 'Pending Fulfillment',
  canceled: 'Canceled',
  shipped: 'Shipped',
  returns: 'Returns',
};

export const OrderStatus = {
  pendingShipment: 'Pending Shipment',
  pendingFulfillment: 'Pending Fulfillment',
  canceled: 'Canceled',
  shipped: 'Shipped',
  returns: 'Returns',
};

export const initialImageCrop = {
  unit: '%',
  width: 50,
  height: 50,
  x: 25,
  y: 25,
};

export const BYTES_FOR_ONE_MB = 1024 * 1024;

export const measurementOptions = [
  {
    key: 'ft',
    text: 'ft',
    value: 'ft',
  },
  {
    key: 'm',
    text: 'm',
    value: 'm',
  },
];

export const bayOptions = [
  {
    key: 'pallet',
    text: 'Pallet',
    value: 'pallet',
  },
  {
    key: 'bin',
    text: 'Bin',
    value: 'bin',
  },
];

export const decimalMask = {
  allowDecimal: true,
  allowLeadingZeroes: false,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
};

export const Tabs = {
  warehouseTabs: {
    details: 'details',
    zones: 'zones',
  },
  settingsTab: {
    integration: 'integration',
    organisation: 'organisation',
    users: 'users',
    warehouse: 'warehouse',
  },
  InventoryTabs: {
    new: 'new',
    feed: 'feed',
  },
  WavesTabs: {
    building: 'building',
    ready: 'ready',
    in_progress: 'in_progress',
    sorting: 'sorting',
    sorted: 'sorted',
    sortedTabs: {
      tote: 'tote',
      single: 'single',
    },
  },
  OrderTabs: {
    pendingFulfillment: 'pending-fulfillment',
    shipped: 'shipped',
  },
};

export const publicStatusOptions = [
  {
    text: 'Public',
    value: 'public',
  },
  {
    text: 'Private',
    value: 'private',
  },
];

export const ShippingTypesIcons = {
  FCL: <ShippingContainer />,
  LCL: <ShippingContainer />,
  FTL: <Truck />,
  LTL: <Truck />,
  SP: <Package />,
};

export const ShippingTypes = [
  {
    text: 'Full Container Load(s)',
    value: 'FCL',
    icon: ShippingTypesIcons.FCL,
  },
  {
    text: 'Less than Container Load',
    value: 'LCL',
    icon: ShippingTypesIcons.LCL,
  },
  {
    text: 'Less than / Full Truck Load(s)',
    value: 'LTL',
    icon: ShippingTypesIcons.LTL,
  },
  {
    text: 'Parcel(s)',
    value: 'SP',
    icon: ShippingTypesIcons.SP,
  },
];

export const StorageIntegrationTypes = {
  FBA: 'spapi',
};

export const unitsTextMap = {
  l: 'Liters',
  L: 'Liters',
  units: 'Units',
  gal: 'Gallons',
  ml: 'Milliliters',
  mL: 'Milliliters',
  'fl oz': 'Fluid Ounces',
  kg: 'Kilograms',
  lb: 'Pounds',
  g: 'Grams',
  oz: 'Ounces',
  cm: 'Centimeters',
  in: 'Inches',
};

export const ShipmentTypeKeys = {
  PO: 'Purchase Order',
  FP: 'Fulfillment Provider',
  SP: 'Storage Provider',
};

export const aisleSides = {
  left: 1,
  right: 2,
};

export const InventoryAdjustmentTypes = {
  increase: 'increase',
  decrease: 'decrease',
  transfer: 'initiate_transfer',
  completeTransfer: 'complete_transfer',
};
