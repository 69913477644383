import { TableCell, TableRow } from 'semantic-ui-react';
import { useEffect, useMemo, useState } from 'react';
import { Check } from '@phosphor-icons/react';
import styled from 'styled-components';
import {
  ActionEditDots,
  ActionMenuItem,
  AppTable,
  ItemImage,
} from '../../../components';
import { GreenPill, GreyPill, MuteText } from '../../../elements';
import { ProductPropertiesConverted } from '../common';
import { ExpandCollapseToggle } from './common';
import { CommonUtility } from '../../../utility';

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    img.m-auto {
      opacity: 0.3;
    }
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function ReadOnlyShipmentItems({
  data,
  imperial,
  search,
  loading,
  openRecievingPopup,
}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    let allItems = JSON.parse(JSON.stringify(data.items || []));
    if (search) {
      allItems = allItems.filter(
        x =>
          CommonUtility.searchQueryCompare(search, x.p_title) ||
          CommonUtility.searchQueryCompare(search, x.p_sku) ||
          CommonUtility.searchQueryCompare(search, x.item_upc),
      );
    }
    setItems(allItems);
  }, [data?.items, search]);

  const columns = [
    {
      text: 'Product Information',
      key: 'product_information',
      width: 1,
      textAlign: 'left',
    },
    {
      text: '',
      key: 'product_details',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Carton Properties',
      key: 'carton_properties',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Location',
      key: 'location',
      width: 1,
    },
    {
      text: 'Status',
      key: 'status',
      width: 1,
    },
  ];

  return (
    <AppTable
      columns={columns}
      RowComponent={
        <ItemRow openRecievingPopup={openRecievingPopup} imperial={imperial} />
      }
      data={items}
      loading={loading}
    />
  );
}

function ItemRow({ item, imperial, openRecievingPopup }) {
  const [open, setOpen] = useState(true);

  const latestAttribute = useMemo(
    () => item.aliases?.[0]?.attributes || item.attributes || {},
    [item],
  );
  const latestAlias = useMemo(
    () =>
      item.aliases?.find(x => CommonUtility.isValidObject(x.attributes)) ||
      item ||
      {},
    [item],
  );

  const isMultiple = useMemo(() => item?.aliases?.length > 1, [item]);
  const alias = useMemo(
    () => item.alias || latestAttribute.alias || item.aliases?.[0]?.alias,
    [item],
  );

  return (
    <>
      <ActionEditTableRow textAlign="left">
        <TableCell>
          <ItemImage
            item={item}
            imageKey="p_images"
            displayImageKey="display_image"
          />
        </TableCell>
        <TableCell>
          <div>
            <div>{item.p_title}</div>
            <div className="d-flex mt-1">
              <MuteText>SKU</MuteText>
              <span className="ml-2">{item.p_sku}</span>
            </div>
            <div className="d-flex mt-1">
              <MuteText>UPC</MuteText>
              <span className="ml-2">{item.item_upc}</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          {isMultiple ? (
            <ExpandCollapseToggle open={open} setOpen={setOpen} />
          ) : (
            <ProductPropertiesConverted
              attributes={{ ...latestAttribute, alias }}
              imperial={imperial}
            />
          )}
        </TableCell>
        <TableCell textAlign="center">{latestAlias?.location?.code}</TableCell>
        <TableCell>
          <div className="d-flex justify-content-center">
            <StatusRenderer
              qty={latestAlias?.qty}
              received_qty={latestAlias?.received_qty}
            />
          </div>
          {!isMultiple && (
            <ActionEditDots preventChangingBg on="hover" position="left center">
              <ActionMenuItem
                onClick={() => {
                  openRecievingPopup(item, latestAlias);
                }}
              >
                <Check />
                Mark Received
              </ActionMenuItem>
            </ActionEditDots>
          )}
        </TableCell>
      </ActionEditTableRow>
      {isMultiple && open && (
        <DetailVariantsRenderer
          openRecievingPopup={openRecievingPopup}
          imperial={imperial}
          item={item}
        />
      )}
    </>
  );
}

function StatusRenderer({ qty, received_qty }) {
  if (qty === received_qty) {
    return (
      <GreenPill>
        <Check />
        <span>Stored</span>
      </GreenPill>
    );
  }

  return (
    <GreyPill>
      <span>Pending Storage</span>
    </GreyPill>
  );
}

const DetailVariantsRenderer = ({ item, imperial, openRecievingPopup }) =>
  item.aliases.map(variant => {
    const alias = variant.alias;
    return (
      <ActionEditTableRow>
        <TableCell>{variant.alias}</TableCell>
        <TableCell />
        <TableCell>
          <ProductPropertiesConverted
            attributes={{ alias, ...variant.attributes }}
            imperial={imperial}
          />
        </TableCell>
        <TableCell>
          <ActionEditDots preventChangingBg on="hover" position="left center">
            <ActionMenuItem
              onClick={() => {
                openRecievingPopup(item, variant);
              }}
            >
              <Check />
              Mark Received
            </ActionMenuItem>
          </ActionEditDots>
        </TableCell>
      </ActionEditTableRow>
    );
  });
