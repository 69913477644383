import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { PencilSimple } from '@phosphor-icons/react';
import { CommonUtility, DateFormat, DateUtility } from '../../utility';
import { MuteText } from '../../elements';
import { ActionEditDots, ActionMenuItem } from '../../components';

const ValueContainer = styled.span`
  text-transform: capitalize;
  margin-left: 7px;
`;

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function WaveRuleRow({ item, editRule }) {
  return (
    <ActionEditTableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        {DateUtility.formatDate(item.createdAt, DateFormat.dateTime)}
      </TableCell>
      <TableCell>
        <div>
          {Object.keys(item.metadata || {}).map(metaKey => (
            <div className="d-flex mb-1">
              <MuteText>{CommonUtility.toTitleCase(metaKey)}</MuteText>
              <ValueContainer>
                {CommonUtility.formatApiKeys(item.metadata[metaKey])}
              </ValueContainer>
            </div>
          ))}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {Object.keys(item.thresholds || {}).map(metaKey => (
            <div className="d-flex mb-1">
              <MuteText>{CommonUtility.toTitleCase(metaKey)}</MuteText>
              <ValueContainer>
                {CommonUtility.formatApiKeys(item.thresholds[metaKey])}
              </ValueContainer>
            </div>
          ))}
        </div>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem
            onClick={() => {
              editRule(item);
            }}
          >
            <PencilSimple />
            Edit
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionEditTableRow>
  );
}
