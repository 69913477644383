import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { RackDetails } from './racks/id';
import { ZoneDetailScreen } from './id';
import { CommonUtility } from '../../../../utility';
import { ZoneHook } from '../../../../hooks/zone';

export function ZoneTab({ zoneOptions, onChangeZone, zoneId, warehouseId }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [rackList, setRackList] = useState([]);

  const rackId = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 8);
    }
    return null;
  }, [pathname]);

  const onChangeRack = rackId => {
    navigate(
      `/app/settings/warehouse/${warehouseId}/zones/${zoneId}/racks/${rackId}`,
    );
  };

  const zoneData = ZoneHook(zoneId, setRackList);

  const rackOptions = useMemo(() => {
    if (CommonUtility.isValidArray(rackList)) {
      return rackList?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [rackList]);

  return (
    <Routes>
      <Route
        index
        element={
          <ZoneDetailScreen
            zoneId={zoneId}
            zoneOptions={zoneOptions}
            onChangeZone={onChangeZone}
            {...zoneData}
          />
        }
      />
      <Route
        path="racks/:rackId"
        element={
          <RackDetails
            rackOptions={rackOptions}
            onChangeRack={onChangeRack}
            rackId={rackId}
          />
        }
      />
    </Routes>
  );
}
