import { Plus, Trash } from '@phosphor-icons/react';
import { FormGroup } from 'semantic-ui-react';
import styled from 'styled-components';
import { useFieldArray } from 'react-hook-form';
import {
  CheckboxFormField,
  ControlledTextFormField,
  DangerText,
  DropdownFormField,
  HoverBorderButton,
  NumberFormField,
} from '../../elements';
import { CommonUtility, theme } from '../../utility';

const typesOfFields = [
  {
    text: 'Text',
    value: 'text',
  },
  {
    text: 'Number',
    value: 'number',
  },
  {
    text: 'Boolean',
    value: 'boolean',
  },
  {
    text: 'Radio',
    value: 'radio',
  },
];

const FieldContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.box};
  border-radius: 5px;
  padding: 10px;
  height: calc(100% - 15px);
  margin-bottom: 15px;
`;

const FieldsHolder = styled.div`
  margin: 0 -10px;
  margin-top: 10px;
`;

export function CustomFields({ customFields, ...props }) {
  return (
    <FieldsHolder className="row">
      {customFields.map((customField, index) => (
        <Field
          key={customField.id}
          customField={customField}
          index={index}
          {...props}
        />
      ))}
    </FieldsHolder>
  );
}

function Field({ customField, index, control, errors, removeField, watch }) {
  const watchName = watch(`customFields[${index}].label`);
  const watchType = watch(`customFields[${index}].type`);
  return (
    <div className="col-6 px-2">
      <FieldContainer key={customField.id}>
        <div className="d-flex align-items-center justify-content-between">
          <b>{watchName || `Field ${index + 1}`}</b>
          <Trash
            color={theme.colors.danger}
            size={20}
            onClick={() => removeField(index)}
            className="cursor-pointer"
            weight="fill"
          />
        </div>
        <FormGroup widths="equal" className="mb-0 pb-0">
          <ControlledTextFormField
            name={`customFields[${index}].label`}
            control={control}
            errors={errors.customFields?.[index]?.label}
            hint={errors.customFields?.[index]?.label?.message}
            label="Label"
            defaultValue={customField?.label}
          />
          {['number', 'text'].includes(watchType) && (
            <ControlledTextFormField
              name={`customFields[${index}].placeholder`}
              control={control}
              errors={errors.customFields?.[index]?.placeholder}
              hint={errors.customFields?.[index]?.placeholder?.message}
              label="Placeholder"
              defaultValue={customField?.placeholder}
            />
          )}
        </FormGroup>
        <FormGroup widths="equal">
          <DropdownFormField
            name={`customFields[${index}].type`}
            control={control}
            errors={errors.customFields?.[index]?.type}
            hint={errors.customFields?.[index]?.type?.message}
            label="Type"
            defaultValue={customField?.type}
            options={typesOfFields}
            direction="upward"
          />
          <CheckboxFormField
            name={`customFields[${index}].required`}
            control={control}
            errors={errors.customFields?.[index]?.required}
            hint={errors.customFields?.[index]?.required?.message}
            defaultValue={customField?.required}
            label="Required"
          />
        </FormGroup>
        {watchType === 'radio' && (
          <Options
            control={control}
            index={index}
            optionsErrors={errors.customFields?.[index]?.options || {}}
          />
        )}
        {watchType === 'number' && (
          <NumberMask
            control={control}
            index={index}
            customField={customField}
            errors={errors.customFields?.[index]?.validation}
          />
        )}
      </FieldContainer>
    </div>
  );
}

function NumberMask({ control, errors, index, customField }) {
  return (
    <div>
      <b>Validation</b>
      <FormGroup widths="equal" className="mt-2">
        <ControlledTextFormField
          name={`customFields[${index}].validation.pattern`}
          control={control}
          errors={errors?.pattern}
          hint={errors?.pattern?.message}
          label="Pattern"
          defaultValue={customField?.validation?.pattern}
        />
        <ControlledTextFormField
          name={`customFields[${index}].validation.message`}
          control={control}
          errors={errors?.message}
          hint={errors?.message?.message}
          label="Message"
          defaultValue={customField?.validation?.message}
        />
        <NumberFormField
          name={`customFields[${index}].validation.min`}
          control={control}
          errors={errors?.min}
          hint={errors?.min?.message}
          label="Maximum"
          defaultValue={customField?.validation?.min}
        />
        <NumberFormField
          name={`customFields[${index}].validation.max`}
          control={control}
          errors={errors?.max}
          hint={errors?.max?.message}
          label="Minimum"
          defaultValue={customField?.validation?.max}
        />
      </FormGroup>
    </div>
  );
}

const OptionsContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
`;

const OptionRenderer = styled.div`
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 5px;
`;

function Options({ control, index, optionsErrors }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `customFields[${index}].options`,
  });

  const addField = () => {
    append({
      value: '',
      label: '',
    });
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <b>Options</b>
      {CommonUtility.isValidArray(fields) ? (
        <OptionsContainer>
          {fields.map((optionField, optionIndex) => (
            <OptionRenderer key={optionField.id}>
              <div className="d-flex align-items-center justify-content-between">
                <b>Option {optionIndex + 1}</b>
                <Trash
                  color={theme.colors.danger}
                  size={20}
                  onClick={() => remove(index)}
                  className="cursor-pointer"
                  weight="fill"
                />
              </div>

              <ControlledTextFormField
                name={`customFields[${index}].options.[${optionIndex}].label`}
                control={control}
                errors={optionsErrors?.[optionIndex]?.label}
                hint={optionsErrors?.[optionIndex]?.label?.message}
                label="Label"
                defaultValue={optionField?.label}
              />
              <ControlledTextFormField
                name={`customFields[${index}].options.[${optionIndex}].value`}
                control={control}
                errors={optionsErrors?.[optionIndex]?.value}
                hint={optionsErrors?.[optionIndex]?.value?.message}
                label="Value"
                defaultValue={optionField?.value}
              />
            </OptionRenderer>
          ))}
        </OptionsContainer>
      ) : optionsErrors?.message ? (
        <DangerText className="my-2">{optionsErrors?.message}</DangerText>
      ) : (
        <OptionsContainer>No Options</OptionsContainer>
      )}

      <HoverBorderButton className="flex-btn" onClick={addField} type="button">
        <Plus className="mr-1" /> Option
      </HoverBorderButton>
    </div>
  );
}
