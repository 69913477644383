import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CaretDown, Waves } from '@phosphor-icons/react';
import { Dropdown } from 'semantic-ui-react';
import {
  BodyContainer,
  HeaderBar,
  PageTitle,
} from '../../../../../elements/Common';
import { TabStyleMenu } from '../../../../../elements/Tab';
import { CommonUtility, Tabs } from '../../../../../utility';
import { WaveListTab } from './list';
import { SortedWaveListTab } from './sortedLists';
import { WarehouseListHook } from '../../../../../hooks';

const StyledDropdown = styled(Dropdown)`
  font-size: 20px;
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0px;
  right: 0.7em;
  z-index: 3;
  right: -24px;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

function Header({
  warehouseOptions,
  warehouseLoading,
  warehouseId,
  setWarehouseId,
}) {
  return (
    <HeaderBar className="row tab-layout">
      <div className="col-9 pt-2 d-flex align-items-center">
        <PageTitle className="d-flex align-items-center">
          <Waves size={25} className="mr-1" /> <span>Waves</span>
        </PageTitle>
        <div className="d-flex align-items-center">
          <PageTitle className="mx-2">for</PageTitle>
          <StyledDropdown
            inline
            options={warehouseOptions}
            value={warehouseId}
            loading={warehouseLoading}
            onChange={(e, { value }) => setWarehouseId(value)}
            icon={<DropdownIcon size={18} />}
          />
        </div>
      </div>
    </HeaderBar>
  );
}

const WaveContext = createContext();

const TabContainer = styled.div`
  margin-top: 40px;
`;

export function WaveListScreen() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [warehouseId, setWarehouseId] = useState('');

  const { data: warehouseData, loading: warehouseLoading } =
    WarehouseListHook();

  const warehouseOptions = useMemo(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      return warehouseData?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [warehouseData]);

  useEffect(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      setWarehouseId(warehouseData[0]._id);
    }
  }, [warehouseData]);

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 5);
    }
    return null;
  }, [pathname]);

  const tabClicked = tabName => {
    navigate(`/app/fulfill/waves/list/${tabName}`);
  };

  const menus = [
    {
      text: 'Building',
      value: Tabs.WavesTabs.building,
    },
    {
      text: 'Ready',
      value: Tabs.WavesTabs.ready,
    },
    {
      text: 'In Progress',
      value: Tabs.WavesTabs.in_progress,
    },
    {
      text: 'Sorting',
      value: Tabs.WavesTabs.sorting,
    },
    {
      text: 'Sorted',
      value: Tabs.WavesTabs.sorted,
    },
  ];

  return (
    <WaveContext.Provider
      value={{
        setWarehouseId,
        warehouseId,
      }}
    >
      <BodyContainer>
        <Header
          setWarehouseId={setWarehouseId}
          warehouseId={warehouseId}
          warehouseOptions={warehouseOptions}
          warehouseLoading={warehouseLoading}
        />
        <TabStyleMenu
          menus={menus}
          currentMenu={currentTab}
          onTabChange={tabClicked}
        />
        <TabContainer>
          <Routes>
            <Route path="sorted/*" element={<SortedWaveListTab />} />
            <Route path="*" element={<WaveListTab />} />
          </Routes>
        </TabContainer>
      </BodyContainer>
    </WaveContext.Provider>
  );
}

export const useWavesFilterContext = () => useContext(WaveContext);
