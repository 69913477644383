import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useMemo, useState } from 'react';
import { CommonUtility, ToastMessage, WaveService } from '../../utility';
import { SkupremeModal } from '../../components';
import {
  ControlledTextFormField,
  DropdownFormField,
  HoverBorderButton,
  NumberFormField,
  StyledButton,
} from '../../elements';
import { WarehouseZonesHook } from '../../hooks/zone';

const ZoneSchema = yup.object().shape({
  name: yup.string().required('Rule name is Required'),
  warehouseId: yup.string().required('Warehouse is Required'),
  description: yup.string(),
  zoneRestrictions: yup.array(),
  orderCount: yup
    .number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  itemCount: yup
    .number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  timeWindow: yup
    .number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  maxOrders: yup
    .number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  maxItems: yup
    .number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
});

export function AddWaveRulePopup({
  refresh,
  open,
  onClose,
  warehouseOptions,
  warehouseLoading,
  item,
}) {
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    // setValue,
    reset,
  } = useForm({
    resolver: yupResolver(ZoneSchema),
    shouldUnregister: true,
  });

  const warehouseId = watch('warehouseId');

  const { data: zones } = WarehouseZonesHook(warehouseId);

  useEffect(() => {
    if (CommonUtility.isValidObject(item)) {
      reset({
        name: item.name,
        description: item.description,
        warehouseId: item.warehouseId._id,
        zoneRestrictions: item.zoneRestrictions?.map(zone => zone._id),
        ...item.thresholds,
      });
    } else {
      reset({
        name: '',
        description: '',
        warehouseId: '',
        zoneRestrictions: [],
        orderCount: '',
        itemCount: '',
        timeWindow: '',
        maxOrders: '',
        maxItems: '',
      });
    }
  }, [item]);

  const zoneOptions = useMemo(() => {
    if (CommonUtility.isValidArray(zones)) {
      return zones?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [zones]);

  const onSubmit = async formData => {
    try {
      setLoading(true);
      let payload = {
        name: formData.name,
        description: formData.description,
        warehouseId: formData.warehouseId,
        zoneRestrictions: formData.zoneRestrictions,
        thresholds: {
          orderCount: formData.orderCount,
          itemCount: formData.itemCount,
          timeWindow: formData.timeWindow,
          maxOrders: formData.maxOrders,
          maxItems: formData.maxItems,
        },
      };
      payload = CommonUtility.removeEmptyFields(payload);
      if (CommonUtility.isValidObject(item)) {
        await WaveService.updateRule(item._id, payload);
      } else {
        await WaveService.addRule(payload);
      }
      ToastMessage.success('Zone successfully created.');
      onClose();
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      size="tiny"
      open={open}
      onClose={onClose}
      title="Add New Rule"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextFormField
          control={control}
          name="name"
          label="Rule Name"
          error={errors.name}
          hint={errors.name?.message}
          required
        />
        <ControlledTextFormField
          control={control}
          name="description"
          label="Description"
          error={errors.description}
          hint={errors.description?.message}
        />
        <FormGroup widths="equal">
          <DropdownFormField
            control={control}
            error={errors.warehouseId}
            hint={errors.warehouseId?.message}
            name="warehouseId"
            label="Warehouse"
            options={warehouseOptions}
            required
            loading={warehouseLoading}
          />
          <DropdownFormField
            control={control}
            error={errors.zoneRestrictions}
            hint={errors.zoneRestrictions?.message}
            name="zoneRestrictions"
            label="Zone Restrictions"
            options={zoneOptions}
            multiple
            selection
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            error={errors.orderCount}
            hint={errors.orderCount?.message}
            name="orderCount"
            label="Order Count"
          />
          <NumberFormField
            control={control}
            error={errors.itemCount}
            hint={errors.itemCount?.message}
            name="itemCount"
            label="Item Count"
          />
          <NumberFormField
            control={control}
            error={errors.timeWindow}
            hint={errors.timeWindow?.message}
            name="timeWindow"
            label="Time Window"
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            error={errors.maxOrders}
            hint={errors.maxOrders?.message}
            name="maxOrders"
            label="Max Orders"
          />
          <NumberFormField
            control={control}
            error={errors.maxItems}
            hint={errors.maxItems?.message}
            name="maxItems"
            label="Max Items"
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            Create
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
