import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { BagSimple, UserCircle } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { OrganisationAvatar } from '../../components/ImageAvatar';
import {
  GreenTag,
  MuteText,
  PrimaryText,
  ShadowBox,
  SuccessText,
} from '../../elements';
import { CommonUtility } from '../../utility/common';
import { theme } from '../../utility/theme';
import { LoaderBar } from '../../components';

export function CompanyRow({ item }) {
  return (
    <TableRow>
      <TableCell>
        <div className="d-flex align-items-center">
          <OrganisationAvatar
            image={item?.organization?.logo?.url}
            name={item.companyName}
          />
          <b className="ml-2">{item.companyName}</b>
        </div>
      </TableCell>
      <TableCell textAlign="center">
        <div className="d-flex justify-content-center">
          <GreenTag>Active</GreenTag>
        </div>
      </TableCell>
      <TableCell textAlign="center">
        {CommonUtility.numberWithCommas(item.mtdOrders)}
      </TableCell>
      <TableCell textAlign="center">
        {CommonUtility.numberWithCommas(item.mtdLabels)}
      </TableCell>
      <TableCell textAlign="center">
        {CommonUtility.currencyFormat(item.totalRate)}
      </TableCell>
      <TableCell textAlign="center">
        {CommonUtility.currencyFormat(item.totalCommission)}
      </TableCell>
    </TableRow>
  );
}

const CardsContainer = styled.div`
  display: flex;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const LargeFont = styled.span`
  font-size: 40px;
  font-weight: bold;
  margin-top: 15px;
`;

const AnalyticCard = styled(ShadowBox)`
  display: flex;
  justify-content: space-between;
  height: 150px;
  min-width: 280px;
  position: relative;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors.green};
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 2px;
`;

const MuteCircle = styled(Circle)`
  background: ${({ theme }) => theme.colors.primary};
`;

export function AnalyticCards({ data, loading, currentOrgMetadata }) {
  const { totalOrders, activeUsers, inActiveUsers, totalUsers } =
    useMemo(() => {
      if (!CommonUtility.isValidObject(data)) return {};

      const { activeCompanies } = data;
      let totalOrders = 0;
      activeCompanies.forEach(aCompnay => {
        totalOrders += aCompnay.mtdOrders;
      });

      return {
        totalOrders,
        activeUsers: activeCompanies?.length || 0,
        inActiveUsers: currentOrgMetadata?.inactiveCustomers || 0,
        totalUsers:
          activeCompanies?.length +
            (currentOrgMetadata?.inactiveCustomers || 0) || 0,
      };
    }, [data, currentOrgMetadata]);

  const chartData = useMemo(
    () => [
      { name: 'Active', value: activeUsers },
      { name: 'Inactive', value: inActiveUsers },
    ],
    [activeUsers, inActiveUsers],
  );

  // const linechartData = [
  //   {
  //     name: 'Page A',
  //     pv: 0,
  //     uv: 30,
  //   },
  //   {
  //     name: 'Page B',
  //     pv: 3,
  //     uv: 12,
  //   },
  //   {
  //     name: 'Page B',
  //     pv: 8,
  //     uv: 5,
  //   },
  //   {
  //     name: 'Page B',
  //     pv: 20,
  //     uv: 10,
  //   },
  // ];

  return (
    <CardsContainer>
      <AnalyticCard>
        {loading ? (
          <LoaderBar />
        ) : (
          <>
            <div className="d-flex flex-column justify-content-between">
              <MuteText className="d-flex align-items-center">
                <UserCircle size={17} />
                <span className="mt-1 ml-2">Clients</span>
              </MuteText>
              <div className="d-flex align-items-end">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <Circle />
                    <MuteText>Active</MuteText>
                    <SuccessText className="mx-2">{activeUsers}</SuccessText>
                    <MuteText>
                      ({Math.round((activeUsers * 100) / (totalUsers || 1))}%)
                    </MuteText>
                  </div>
                  <div className="d-flex align-items-center mt-1">
                    <MuteCircle />
                    <MuteText>Inactive</MuteText>
                    <PrimaryText className="mx-2">
                      {CommonUtility.numberWithCommas(inActiveUsers)}
                    </PrimaryText>
                    <MuteText>
                      (
                      {CommonUtility.numberWithCommas(
                        Math.round((inActiveUsers * 100) / (totalUsers || 1)),
                      )}
                      %)
                    </MuteText>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-4 pl-4 pt-1">
              <PieChart width={100} height={100}>
                <text
                  x={50}
                  y={50}
                  fill={theme.colors.grey}
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  {CommonUtility.numberWithCommas(totalUsers) || 0}
                </text>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={33}
                  outerRadius={45}
                  paddingAngle={0}
                >
                  <Cell key="cell-1" fill={theme.colors.green} />
                  <Cell key="cell-2" fill={theme.colors.primary} />
                </Pie>
              </PieChart>
            </div>
          </>
        )}
      </AnalyticCard>
      <AnalyticCard>
        {loading ? (
          <LoaderBar />
        ) : (
          <>
            <div className="d-flex flex-column justify-content-between ">
              <MuteText className="d-flex align-items-center">
                <BagSimple size={17} />
                <span className="mt-1 ml-2">Orders Per Month</span>
              </MuteText>
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <LargeFont className="mb-2">
                    {CommonUtility.numberWithCommas(totalOrders)}
                  </LargeFont>
                  <div className="mt-4 pt-4" />
                  {/* <div className="d-flex mt-4 pt-2">
                    <MuteText>Up by</MuteText>
                    <SuccessText className="ml-1">50%</SuccessText>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="ml-4 pl-4 pt-4">
              <LineChart width={100} height={100} data={linechartData}>
                <Line
                  dot={false}
                  type="monotone"
                  dataKey="uv"
                  stroke={theme.colors.box}
                  strokeWidth={3}
                />
                <Line
                  dot={false}
                  type="monotone"
                  dataKey="pv"
                  stroke={theme.colors.green}
                  strokeWidth={3}
                />
              </LineChart>
            </div> */}
          </>
        )}
      </AnalyticCard>
    </CardsContainer>
  );
}
