import { useEffect, useState } from 'react';
import { CommonUtility, WaveService } from '../utility';
import { useDebouncedEffect } from './debounce';

export const WaveListHook = (status, warehouseId) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        ...filter,
        status,
        warehouseId,
      };
      const res = await WaveService.waveList(params);
      setData(res?.data);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (warehouseId) {
        fetchData();
      }
    },
    [filter, status, warehouseId],
    300,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
  };
};

export const WaveRuleListHook = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: null,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      let params = { ...filter };
      params = CommonUtility.removeEmptyFields(params);
      const res = await WaveService.waveRuleList(params);
      setData(res?.rules);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (filter.limit) {
        fetchData();
      }
    },
    [filter, refreshKey],
    500,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  const updateAllFilter = obj => {
    setFilter(filter => ({
      ...filter,
      page: 1,
      ...obj,
    }));
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
    refresh,
    updateAllFilter,
  };
};

export const WaveDetailHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await WaveService.getById(id);
      setData(res?.data || {});
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, refreshKey]);

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  return { data, loading, error, refresh, setLoading };
};

export const SortedWaveListHook = warehouseId => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        ...filter,
        warehouseId,
      };
      const res = await WaveService.sortedWaveList(params);
      setData(res?.data?.allocations || []);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (warehouseId) fetchData();
    },
    [filter, warehouseId, refreshKey],
    100,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
    refresh,
  };
};
