import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BodyContainer } from '../../../../elements';
import { WaveDetailHook } from '../../../../hooks/waves';
import { AppTable } from '../../../../components/AppTable';
import {
  TotePopup,
  WaveDetailsHeader,
  WaveItemRow,
} from '../../../../page-components/waves';
import { CommonUtility, ToastMessage, WaveService } from '../../../../utility';

export function WaveDetailScreen() {
  const { id } = useParams();

  const { data, loading, refresh, setLoading } = WaveDetailHook(id);
  const [totePopupItem, setTotePopupItem] = useState(null);

  const [itemMarkedTracker, setItemMarkedTracker] = useState({});

  const columns = [
    {
      text: 'Item',
      key: 'item',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Allocation Time',
      key: 'allocation_time',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Zone',
      key: 'zone',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Rack',
      key: 'rack',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Location',
      key: 'location',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Quantity',
      key: 'quantity',
      width: 1,
    },
    {
      text: '',
      key: 'action',
      width: 2,
      textAlign: 'left',
    },
  ];

  useEffect(() => {
    if (CommonUtility.isValidObject(data?.wave)) {
      const currentStatus = {};
      data?.wave?.allocations?.forEach(allocation => {
        currentStatus[allocation?.id] = allocation.status;
      });

      setItemMarkedTracker(currentStatus);
    }
  }, [data]);

  const setItem = (itemId, value) => {
    setItemMarkedTracker(itemMarkedTracker => ({
      ...itemMarkedTracker,
      [itemId]: value,
    }));
  };

  const checkIfAllItemsHaveBeenPicked = statusTracker => {
    const statusArray = Object.values(statusTracker || {});
    const everyItemIsPicked = statusArray.every(x => x === statusArray[0]);
    if (everyItemIsPicked) {
      refresh();
    }
  };

  const markItemPicked = async item => {
    const previousStatus = item.status;
    try {
      setItem(item.id, 'loading');
      const payload = {
        pickedQuantity: {
          value: item.quantity,
          uom: 'units',
        },
      };
      const res = await WaveService.pickWaveAllocation(id, item.id, payload);
      setItem(item.id, res?.data?.status);
      checkIfAllItemsHaveBeenPicked({
        ...itemMarkedTracker,
        [item.id]: res?.data?.status,
      });
      ToastMessage.success('Item marked as picked successfully');
    } catch (error) {
      setItem(item.id, previousStatus);
      ToastMessage.apiError(error);
    }
  };

  const markWaveReady = async () => {
    try {
      setLoading(true);
      await WaveService.markWaveReady(id);
      ToastMessage.success('Wave marked as ready successfully');
      refresh();
    } catch (error) {
      setLoading(false);
      ToastMessage.apiError(error);
    }
  };

  const sendItemForAllocation = async (item, toteId) => {
    const previousStatus = item.status;
    try {
      setItem(item.id, 'loading');

      const payload = {};
      if (item.sorting?.requiresTote) {
        payload.toteId = toteId;
      }

      const res = await WaveService.sortSingleItem(id, item.id, payload);
      setItem(item.id, res?.data?.status);
      checkIfAllItemsHaveBeenPicked({
        ...itemMarkedTracker,
        [item.id]: res?.data?.status,
      });
      ToastMessage.success('Item marked as picked successfully');
    } catch (error) {
      setItem(item.id, previousStatus);
      ToastMessage.apiError(error);
    }
  };

  const markItemAsAllocated = item => {
    if (item.sorting?.requiresTote) {
      setTotePopupItem(item);
      return;
    }

    sendItemForAllocation(item);
  };

  const closeTotePopup = () => {
    setTotePopupItem(null);
  };

  return (
    <BodyContainer>
      <WaveDetailsHeader
        itemMarkedTracker={itemMarkedTracker}
        data={data?.wave}
        summary={data?.summary}
        markWaveReady={markWaveReady}
        loading={loading}
        isWaveInBuilding={data?.wave?.status === 'building'}
      />
      <h3 className="mt-1">Items</h3>
      <AppTable
        columns={columns}
        loading={loading}
        data={data?.wave?.allocations}
        RowComponent={
          <WaveItemRow
            markItemPicked={markItemPicked}
            itemMarkedTracker={itemMarkedTracker}
            isWaveInBuilding={data?.wave?.status === 'building'}
            isWaveInSorting={data?.wave?.status === 'sorting'}
            markItemAsAllocated={markItemAsAllocated}
          />
        }
      />
      <TotePopup
        item={totePopupItem}
        open={!!totePopupItem}
        onClose={closeTotePopup}
        warehouse={data?.wave?.warehouseId}
        afterToteCreation={sendItemForAllocation}
      />
    </BodyContainer>
  );
}
