import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { SkupremeModal } from '../../components';
import {
  HoverBorderButton,
  NumberFormField,
  StyledButton,
} from '../../elements';
import { decimalMask, ToastMessage, ZoneService } from '../../utility';
import { useWarehouseDetails } from '../../screens/app/settings/warehouse';

const AisleSchema = yup.object().shape({
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
});

export function EditAislePopup({ onClose, zoneId, open, refreshData, aisle }) {
  const [loading, setLoading] = useState(false);
  const { warehouseData } = useWarehouseDetails();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(AisleSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    reset({
      length: aisle?.dimensions?.length || '',
      width: aisle?.dimensions?.width || '',
    });
  }, [aisle]);

  const onSubmit = async formData => {
    if (!warehouseData?.dimensions?.measurementUnit) {
      ToastMessage.error('No Measurement Unit found for warehouse');
      return;
    }
    try {
      setLoading(true);
      const payload = {
        zoneId,
        dimensions: {
          length: formData.length,
          width: formData.width,
          measurementUnit: warehouseData.dimensions.measurementUnit,
        },
      };
      await ZoneService.updateAisle(zoneId, aisle?._id, payload);
      ToastMessage.success('Aisles successfully created.');
      onClose();
      refreshData();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      size="mini"
      title={`Edit Aisle ${aisle?.code}`}
      open={open}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            name="length"
            label="Length"
            maskOptions={decimalMask}
            error={errors.length}
            hint={errors.length?.message}
          />
          <NumberFormField
            control={control}
            name="width"
            label="Width"
            maskOptions={decimalMask}
            error={errors.width}
            hint={errors.width?.message}
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            Update
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
