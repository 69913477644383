import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { AuthGuard } from './guards';
import {
  AcceptInviteScreen,
  Error404,
  HomeScreen,
  InventoryDetailScreen,
  LoginScreen,
  OrderDetailScreen,
  OrderListScreen,
  ShipmentDetailScreen,
  ShipmentListScreen,
  WaveListScreen,
  WaveRulesScreen,
} from './screens';
import { AppLayout, SecureMenu } from './layouts';
import { SidebarProvider } from './contexts/sidebar';
import { RegisterScreen } from './screens/register';
import { OnboardingScreen } from './screens/app/onboarding';
import { SettingsDetailScreen } from './screens/app/settings';
import { InventoryListScreen } from './screens/app/inventory/list';
import { WaveDetailScreen } from './screens/app/fulfill/waves/detail';
import { InventoryTransferListScreen } from './screens/app/inventory/transfer-list';
import { ExpirationListScreen } from './screens/app/inventory/expirations';

function App() {
  const { pathname } = useLocation();
  const blockedUrls = ['/app/onboarding', '/app/onboarding/'];

  const hideSidebar = useMemo(() => blockedUrls.includes(pathname), [pathname]);

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/accept-invite" element={<AcceptInviteScreen />} />
      <Route path="/" exact element={<Navigate to="/login" replace />} />
      <Route
        path="app"
        element={
          <AuthGuard>
            <SidebarProvider>
              <SecureMenu hideSidebar={hideSidebar} />
              <AppLayout hideSidebar={hideSidebar} />
            </SidebarProvider>
          </AuthGuard>
        }
      >
        <Route path="onboarding" element={<OnboardingScreen />} />
        <Route path="settings/*" element={<SettingsDetailScreen />} />
        <Route path="inventory">
          <Route path="import/*" element={<InventoryDetailScreen />} />
          <Route path="list" element={<InventoryListScreen />} />
          <Route path="transfer" element={<InventoryTransferListScreen />} />
          <Route path="expirations" element={<ExpirationListScreen />} />
        </Route>
        <Route path="shipments">
          <Route index element={<ShipmentListScreen />} />
          <Route path=":id" element={<ShipmentDetailScreen />} />
        </Route>
        <Route path="fulfill">
          <Route path="waves/list/*" element={<WaveListScreen />} />
          <Route path="waves/rules" element={<WaveRulesScreen />} />
          <Route path="waves/:id" element={<WaveDetailScreen />} />
          <Route path="orders/details/:id" element={<OrderDetailScreen />} />
          <Route path="orders/*" element={<OrderListScreen />} />
        </Route>
        <Route path="dashboard" element={<HomeScreen />} />
        <Route index element={<Navigate to="dashboard" replace />} />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default App;
