import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserService } from '../utility/services/user';
import { CommonUtility } from '../utility/common';
import { AccountService } from '../utility/services/account';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const setUserData = userObject => {
    const authenticated = !!userObject.accessToken;
    setIsAuthenticated(authenticated);
    if (authenticated) {
      const user = userObject.user;
      setUser(user);
    }
  };

  const refreshUserData = async () => {
    try {
      const res = await AccountService.get();
      UserService.storeUser(res);
      setUserData(res);
    } catch (error) {
      logout();

      setTimeout(() => {
        const query = {
          redirectUrl: `${window.location.pathname}${window.location.search}`,
        };
        navigate('/login', {
          state: {
            query,
          },
        });
      }, [300]);
    }
  };

  useEffect(() => {
    if (!isAuthenticated || !user) {
      return;
    }

    if (
      !CommonUtility.isValidArray(user?.organizations) &&
      !pathname.includes('onboarding')
    ) {
      navigate('/app/onboarding');
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const loadToken = async () => {
      const authenticated = UserService.isAuthenticated();
      if (authenticated) {
        await refreshUserData();
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };
    loadToken();
  }, []);

  const logout = () => {
    setIsAuthenticated(false);
    UserService.logout();
  };

  const updateUserDetails = userDetails => {
    setUser(userDetails);
    const updatedUser = UserService.getUser();
    updatedUser.user = userDetails;
    UserService.storeUser(updatedUser);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        isAuthenticated,
        logout,
        updateUserDetails,
        refreshUserData,
        setUserData,
      }}
    >
      <>{children}</>
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
