import { WarehoueZones } from '.';
import { WarehouseForm } from './warehouseForm';

export function WarehouseDetailTab({ zones, zoneLoading, zoneRefresh }) {
  return (
    <div className="row mt-2">
      <div className="col-7 pl-0 pr-0">
        <WarehoueZones
          zones={zones}
          zoneLoading={zoneLoading}
          zoneRefresh={zoneRefresh}
        />
      </div>
      <div className="pr-0 col-5">
        <WarehouseForm />
      </div>
    </div>
  );
}
