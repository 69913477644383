import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import {
  ControlledTextFormField,
  HoverBorderButton,
  MuteText,
  NumberFormField,
  ShadowBox,
  StyledButton,
} from '../../elements';
import {
  CommonUtility,
  decimalMask,
  ToastMessage,
  ZoneService,
} from '../../utility';
import { LoaderBar } from '../../components';

const RackShema = yup.object().shape({
  code: yup.string().required('Code is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
  height: yup
    .number()
    .positive('Height must be greater than 0')
    .typeError('Height is required')
    .required('Height is required'),
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
});

export function RackDimensionsDetails({ data, detailLoading }) {
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors, dirtyFields },
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(RackShema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (CommonUtility.isValidObject(data)) resetForm();
  }, [data]);

  const resetForm = () => {
    reset({
      code: data?.code,
      ...data?.dimensions,
    });
  };

  const onSubmit = async formData => {
    try {
      setLoading(true);
      const payload = {
        code: formData.code,
        dimensions: {
          length: formData.length,
          width: formData.width,
          height: formData.height,
        },
      };

      const res = await ZoneService.updateRack(
        data?.zone?._id,
        data?._id,
        payload,
      );
      ToastMessage.success('Rack successfully updated.');
      reset({
        code: res.code,
        ...res?.dimensions,
      });
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ShadowBox className="position-relative">
      {detailLoading && <LoaderBar />}
      <div className="d-flex">
        <b>{data?.code}</b>
        <div className="ml-2">
          <MuteText>Dimensions</MuteText>
          <span className="ml-2">{data.dimensions?.length}</span>
          <MuteText className="mx-1">x</MuteText>
          <span>{data.dimensions?.width}</span>
          <MuteText className="mx-1">
            ({data.dimensions?.measurementUnit})
          </MuteText>
        </div>
      </div>
      <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextFormField
          control={control}
          name="code"
          label="Code"
          error={errors.code}
          hint={errors.code?.message}
        />
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            name="length"
            label="Length"
            maskOptions={decimalMask}
            error={errors.length}
            hint={errors.length?.message}
          />
          <NumberFormField
            control={control}
            name="width"
            label="Width"
            maskOptions={decimalMask}
            error={errors.width}
            hint={errors.width?.message}
          />
          <NumberFormField
            control={control}
            name="height"
            label="Height"
            maskOptions={decimalMask}
            error={errors.height}
            hint={errors.height?.message}
          />
        </FormGroup>
        {CommonUtility.isValidObject(dirtyFields) && (
          <div className="d-flex justify-conten-end">
            <HoverBorderButton
              disabled={loading}
              type="button"
              onClick={resetForm}
            >
              Cancel
            </HoverBorderButton>
            <StyledButton loading={loading} type="submit" className="ml-2">
              Update
            </StyledButton>
          </div>
        )}
      </Form>
    </ShadowBox>
  );
}
