import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { CaretDown, GearSix } from '@phosphor-icons/react';
import { BodyContainer, HeaderBar, PageTitle } from '../../../elements';
import { CommonUtility, Tabs } from '../../../utility';
import { TabStyleMenu } from '../../../elements/Tab';
import { GeneralSettingsTab } from './integration';
import { OrganisationDetailTab } from './details';
import { UsersTab } from './users';
import { WarehouseTab } from './warehouse';
import { WarehouseListHook } from '../../../hooks';
import { BackButton } from '../../../components';

const StyledDropdown = styled(Dropdown)`
  font-size: 20px;
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0px;
  right: 0.7em;
  z-index: 3;
  right: -24px;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

function Header({
  warehouseId,
  warehouseLoading,
  warehouseOptions,
  setWarehouseId,
  isWarehouseTab,
}) {
  return (
    <HeaderBar className="row tab-layout">
      <div className="col-6 d-flex align-items-center">
        {isWarehouseTab && <BackButton />}
        <PageTitle className="d-flex align-items-center">
          <GearSix size={25} className="mr-1" /> <span>Settings</span>
        </PageTitle>
        {isWarehouseTab && (
          <div className="d-flex align-items-center">
            <PageTitle className="mx-2">for</PageTitle>
            <StyledDropdown
              inline
              options={warehouseOptions}
              value={warehouseId}
              loading={warehouseLoading}
              onChange={(e, { value }) => setWarehouseId(value)}
              icon={<DropdownIcon size={18} />}
            />
          </div>
        )}
      </div>
    </HeaderBar>
  );
}

const TabContainer = styled.div`
  margin-top: 20px;
`;

export function SettingsDetailScreen() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    data: warehouseList,
    loading: warehouseLoading,
    refresh,
  } = WarehouseListHook();

  const warehouseOptions = useMemo(
    () =>
      warehouseList?.map(warehouse => ({
        value: warehouse._id,
        text: warehouse.name,
      })),
    [warehouseList],
  );

  const warehouseId = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 4);
    }
    return null;
  }, [pathname]);

  useEffect(() => {}, [warehouseList]);

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 3);
    }
    return null;
  }, [pathname]);

  const tabClicked = tabName => {
    navigate(`/app/settings/${tabName}`);
  };

  const menus = [
    {
      text: 'Organization Settings',
      value: Tabs.settingsTab.organisation,
    },
    {
      text: 'Integration Settings',
      value: Tabs.settingsTab.integration,
    },
    {
      text: 'Users',
      value: Tabs.settingsTab.users,
    },
    {
      text: 'Warehouse',
      value: Tabs.settingsTab.warehouse,
    },
  ];

  const onChangeWarehouse = warehouseId => {
    navigate(`/app/settings/warehouse/${warehouseId}`);
  };

  const onCreateWarehouse = id => {
    onChangeWarehouse(id);
    refresh();
  };

  const isWarehouseTab = currentTab === Tabs.settingsTab.warehouse;

  useEffect(() => {
    if (
      CommonUtility.isValidArray(warehouseList) &&
      !warehouseId &&
      isWarehouseTab
    ) {
      onChangeWarehouse(warehouseList[0]?._id);
    }
  }, [isWarehouseTab]);

  return (
    <BodyContainer>
      <Header
        setWarehouseId={onChangeWarehouse}
        warehouseId={warehouseId}
        warehouseLoading={warehouseLoading}
        warehouseOptions={warehouseOptions}
        isWarehouseTab={isWarehouseTab}
      />
      <TabStyleMenu
        menus={menus}
        currentMenu={currentTab}
        onTabChange={tabClicked}
      />
      <TabContainer>
        <Routes>
          <Route index element={<Navigate to="organisation" />} />
          <Route path="integration" element={<GeneralSettingsTab />} />
          <Route path="organisation" element={<OrganisationDetailTab />} />
          <Route path="users" element={<UsersTab />} />
          <Route
            path="warehouse/*"
            element={
              <WarehouseTab
                warehouseId={warehouseId}
                onCreateWarehouse={onCreateWarehouse}
              />
            }
          />
        </Routes>
      </TabContainer>
    </BodyContainer>
  );
}
