import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Zone extends CRUDService {
  constructor() {
    super(APIPath.zones);
  }

  updateWarehouse(id, payload) {
    return BaseService.patch(`${APIPath.zones}/${id}`, payload);
  }

  types() {
    return BaseService.get(APIPath.zoneTypes);
  }

  zoneAisles(id) {
    return BaseService.get(`${APIPath.zones}/${id}/aisles`);
  }

  zoneRacks(id) {
    return BaseService.get(`${APIPath.zones}/${id}/racks`);
  }

  addRacks(id, data) {
    return BaseService.post(`${APIPath.zones}/${id}/racks`, data);
  }

  addAisles(id, data) {
    return BaseService.post(`${APIPath.zones}/${id}/aisles`, data);
  }

  updateAisle(zoneId, aisleId, data) {
    return BaseService.patch(
      `${APIPath.zones}/${zoneId}/aisles/${aisleId}`,
      data,
    );
  }

  updateBay(payload) {
    return BaseService.put(APIPath.rackLocations, payload);
  }

  updateRack(zoneId, rackId, payload) {
    return BaseService.patch(
      `${APIPath.zones}/${zoneId}/racks/${rackId}`,
      payload,
    );
  }

  updateZone(zoneId, data) {
    return BaseService.patch(`${APIPath.zones}/${zoneId}`, data);
  }

  updatePositions(zoneId, data) {
    return BaseService.patch(
      `${APIPath.zones}/${zoneId}/racks/positions`,
      data,
    );
  }
}

const ZoneService = new Zone();
Object.freeze(ZoneService);
export { ZoneService };
