import { Envelope, MapPin, User } from '@phosphor-icons/react';
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
} from 'semantic-ui-react';
import { ShadowBox } from '../../../elements';
import { CommonUtility } from '../../../utility/common';
import { theme } from '../../../utility';

export function CustomerDeatils({ data, loading }) {
  return (
    <ShadowBox className="d-flex align-items-center">
      {loading ? (
        <Placeholder className="w-100">
          <PlaceholderParagraph>
            <PlaceholderLine />
            <PlaceholderLine />
          </PlaceholderParagraph>
        </Placeholder>
      ) : (
        <>
          <div className="d-flex align-items-center">
            <h3>Customer</h3>
          </div>
          <div className="d-flex ml-4 pl-4">
            <User size={20} color={theme.colors.grey} />
            <span className="ml-1">{data?.shippingAddress?.name}</span>
          </div>
          {data?.shippingAddress?.email && (
            <div className="d-flex ml-4 pl-4">
              <Envelope size={20} color={theme.colors.grey} />
              <span className="ml-1">{data?.shippingAddress?.email}</span>
            </div>
          )}
          <div className="d-flex ml-4 pl-4">
            <MapPin size={20} color={theme.colors.grey} />
            <span className="ml-1">
              {CommonUtility.getLongAddress(data?.shippingAddress || [])}
            </span>
          </div>
        </>
      )}
    </ShadowBox>
  );
}
