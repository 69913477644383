import { useEffect, useState } from 'react';
import { ZoneService } from '../utility/services';
import { useDebouncedEffect } from './debounce';
import { aisleSides, CommonUtility, ToastMessage } from '../utility';

export const WarehouseZonesHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const param = {
        warehouseId: id,
      };
      const res = await ZoneService.get(param);
      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refresh: fetchData };
};

export const WarehouseZoneTypesHook = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { types } = await ZoneService.types();
      let options = Object.keys(types || {}).map(zoneKey => {
        const zoneType = types[zoneKey];
        return {
          text: zoneType.description || zoneKey,
          value: zoneKey,
          key: zoneKey,
        };
      });
      setData(options);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const ZoneHook = (id, setRackList) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [count, setCount] = useState({
    aisles: 0,
    racks: 0,
  });
  const [aloneRacks, setAloneRacks] = useState([]);
  const [originalRacks, setOriginalRacks] = useState([]);
  const [startSyncing, setStartSyncing] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      setStartSyncing(false);
      let aisles = await ZoneService.zoneAisles(id);
      const racks = await ZoneService.zoneRacks(id);
      setCount({
        racks: racks?.total || 0,
        aisles: aisles?.total || 0,
      });

      if (CommonUtility.isFunction(setRackList)) {
        setRackList(racks.racks);
      }

      setOriginalRacks(
        racks.racks?.map(rack => ({
          rackId: rack._id,
          position: rack.position,
          aisleId: rack.aisleId,
          aisleSide: rack.aisleSide,
        })),
      );

      aisles = aisles.aisles.map(aisle => {
        aisle.racks = racks.racks?.filter?.(x => x.aisleId === aisle._id);
        return aisle;
      });

      const racksNotInAny = racks.racks?.filter(
        x =>
          !aisles?.find(aisle => aisle.racks.find(rack => rack._id === x._id)),
      );
      setAloneRacks(racksNotInAny);

      aisles = aisles.map(aisle => {
        aisle.left = aisle.racks?.filter?.(
          x => x.aisleSide === aisleSides.left,
        );
        aisle.right = aisle.racks?.filter?.(
          x => x.aisleSide !== aisleSides.left,
        );
        return aisle;
      });

      setData(aisles);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const getUpdatedRacks = () => {
    let newRacks = [];

    data.forEach(aisle => {
      newRacks.push(
        ...aisle.right.map((rack, index) => ({ ...rack, position: index + 1 })),
      );
      newRacks.push(
        ...aisle.left.map((rack, index) => ({ ...rack, position: index + 1 })),
      );
    });

    newRacks.push(
      ...aloneRacks.map((rack, index) => ({ ...rack, position: index + 1 })),
    );

    newRacks = newRacks.map(rack => ({
      rackId: rack._id,
      position: rack.position,
      aisleId: rack.aisleId,
      aisleSide: rack.aisleSide,
    }));

    return CommonUtility.removeEmptyFields(newRacks);
  };

  const apiForPositions = async () => {
    const newRacks = getUpdatedRacks();
    const changedRacks = newRacks.filter(newRack => {
      const oldRack = originalRacks.find(x => x.rackId === newRack.rackId);
      if (oldRack) {
        const parsedOldRack = CommonUtility.removeEmptyFields(oldRack);
        const parsedNewRack = CommonUtility.removeEmptyFields(newRack);
        const areSame =
          JSON.stringify(parsedOldRack) === JSON.stringify(parsedNewRack);
        return !areSame;
      }
      return true;
    });
    try {
      setOriginalRacks(newRacks);
      await ZoneService.updatePositions(id, { updates: changedRacks });
    } catch (error) {
      ToastMessage.apiError(error);
    }
  };

  useDebouncedEffect(
    () => {
      if (startSyncing) {
        apiForPositions();
      }
    },
    [data, startSyncing, aloneRacks],
    1000,
  );

  const updatePositions = () => {
    setStartSyncing(true);
  };

  return {
    data,
    loading,
    error,
    refreshData: fetchData,
    count,
    aloneRacks,
    setData,
    setAloneRacks,
    updatePositions,
  };
};

export const ZoneDetailHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await ZoneService.getById(id);
      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refresh: fetchData };
};
