import { useEffect, useState } from 'react';
import { WaveService } from '../utility';
import { useDebouncedEffect } from './debounce';

export const ItemAllocationToteDetails = allocationId => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await WaveService.getAllocationTote(allocationId);
      setData(res?.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (allocationId) {
      fetchData();
    } else {
      setData({});
    }
  }, [allocationId]);

  return { data, loading, error };
};

export const ToteListWarehouseHook = warehouseId => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        ...filter,
        status: 'ready_to_pack',
      };
      const res = await WaveService.toteList(warehouseId, params);
      setData(res?.data?.totes || []);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (warehouseId) fetchData();
    },
    [filter, warehouseId, refreshKey],
    100,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const refresh = () => {
    setRefreshKey(refreshKey => refreshKey + 1);
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
    refresh,
  };
};
