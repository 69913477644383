import { Check, Circle, Cube, Warehouse, Waves } from '@phosphor-icons/react';
import styled from 'styled-components';
import {
  ActionEditDots,
  ActionMenuItem,
  BackButton,
} from '../../../components';
import { HeaderBar, OrangePill, PageTitle, WhitePill } from '../../../elements';
import { CommonUtility, theme } from '../../../utility';

const Info = styled(WhitePill)`
  height: 24px;
  padding: 0 10px;
`;

const Status = styled(OrangePill)`
  height: 24px;
  padding: 0 10px;
`;

export function WaveDetailsHeader({
  data,
  summary,
  markWaveReady,
  loading,
  isWaveInBuilding,
}) {
  return (
    <HeaderBar className="row">
      <div className="col-10 d-flex">
        <BackButton />
        <div className="d-flex align-items-center">
          <PageTitle className="d-flex align-items-center">
            <Waves size={25} />
            <span className="ml-2">{data?.waveNumber}</span>
          </PageTitle>
          <Status className="ml-4">
            <Circle weight="fill" color={theme.colors.orange} />
            {CommonUtility.toTitleCase(data?.status || '')}
          </Status>
          <Info className="ml-4">
            <Warehouse />
            {data?.warehouseId?.name}
          </Info>
          <Info className="ml-4">
            <Cube />
            {summary?.totalAllocations} Items,
            {summary?.allocationsByStatus?.pending || 0} Pending
          </Info>
        </div>
      </div>
      <div className="col-2 d-flex justify-content-end">
        {isWaveInBuilding && (
          <ActionEditDots
            position="bottom right"
            preventChangingBg
            showByDefault
            inline
          >
            <ActionMenuItem loading={loading} onClick={markWaveReady}>
              <Check />
              Mark Wave Ready
            </ActionMenuItem>
          </ActionEditDots>
        )}
      </div>
    </HeaderBar>
  );
}
