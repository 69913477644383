import { useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Menu, MenuItem } from 'semantic-ui-react';
import styled from 'styled-components';
import { CommonUtility, Tabs } from '../../../../../../utility';
import { ToteTab } from './tote';
import { SingleTab } from './single';
import { useWavesFilterContext } from '..';

const MenuContainer = styled.div`
  margin-top: -10px;
`;

export function SortedWaveListTab() {
  const { pathname } = useLocation();
  const { warehouseId } = useWavesFilterContext();
  const navigate = useNavigate();
  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 6);
    }
    return null;
  }, [pathname]);

  const tabClicked = tabName => {
    navigate(`/app/fulfill/waves/list/sorted/${tabName}`);
  };

  const menus = [
    {
      text: 'Tote',
      value: Tabs.WavesTabs.sortedTabs.tote,
    },
    {
      text: 'Single',
      value: Tabs.WavesTabs.sortedTabs.single,
    },
  ];

  return (
    <div>
      <MenuContainer className="d-flex justify-content-between align-items-center">
        <Menu pointing secondary className="m-0">
          {menus.map(menu => (
            <MenuItem
              name={menu.text}
              active={currentTab === menu.value}
              onClick={() => tabClicked(menu.value)}
            />
          ))}
        </Menu>
      </MenuContainer>
      <Routes>
        <Route index element={<Navigate to="tote" />} />
        <Route path="tote" element={<ToteTab warehouseId={warehouseId} />} />
        <Route
          path="single"
          element={<SingleTab warehouseId={warehouseId} />}
        />
      </Routes>
    </div>
  );
}
