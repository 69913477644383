import { Form } from 'semantic-ui-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ItemImage, SkupremeModal } from '../../components';
import {
  ControlledTextAreaFormField,
  DropdownFormField,
  HoverBorderButton,
  MuteText,
  NumberFormField,
  StyledButton,
  StyledCheckbox,
} from '../../elements';
import {
  CommonUtility,
  InventoryAdjustmentTypes,
  InventoryService,
  ToastMessage,
} from '../../utility';
import { useDebouncedEffect } from '../../hooks';

const ItemSchema = yup.object().shape({
  inventoryId: yup.string().when('adjustmentType', (adjustmentType, schema) => {
    if (adjustmentType?.includes(InventoryAdjustmentTypes.transfer)) {
      return schema.required('Select a Lot').nonNullable('Select a Lot');
    }
    return schema.optional().nullable();
  }),
  adjustmentType: yup.string().required('Adjustment type is required'),
  quantity: yup.number().positive('Quantiy is required'),
  note: yup.string().required('Note is required'),
  destinationLocationId: yup
    .string()
    .when('adjustmentType', (adjustmentType, schema) => {
      if (adjustmentType?.includes(InventoryAdjustmentTypes.transfer)) {
        return schema
          .required('Destination is required')
          .nonNullable('Destination is required');
      }
      return schema.optional().nullable();
    }),
});

const adjusmentOptions = [
  {
    text: 'Dispose',
    value: InventoryAdjustmentTypes.decrease,
    key: InventoryAdjustmentTypes.decrease,
  },
  {
    text: 'Transfer',
    value: InventoryAdjustmentTypes.transfer,
    key: InventoryAdjustmentTypes.transfer,
  },
];

export function InventoryExpirationPopup({
  onClose,
  item,
  refresh,
  open,
  warehouseId,
}) {
  const [loading, setLoading] = useState(false);
  const [destinationLoading, setDestinationLoading] = useState(false);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [lotValue, setLotValue] = useState({});

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(ItemSchema),
    shouldUnregister: true,
    mode: 'onChange',
  });

  const [inventoryId, quantity, adjustmentType] = watch([
    'inventoryId',
    'quantity',
    'adjustmentType',
  ]);

  const onConfirm = async formValues => {
    setLoading(true);
    if (formValues.adjustmentType === InventoryAdjustmentTypes.transfer) {
      const success = await apiCall(formValues);
      if (success) {
        refresh();
        ToastMessage.success('Inventory adjusted successfully');
        onClose();
      }
    } else {
      const selectedLots = Object.keys(lotValue || {})
        .filter(lotId => !!lotValue[lotId])
        ?.map(lotKey => lotKey);

      if (!CommonUtility.isValidArray(selectedLots)) {
        ToastMessage.error('Select Lots Please');
      } else {
        let anyFailed;
        for (let index = 0; index < selectedLots.length; index += 1) {
          const lotId = selectedLots[index];
          formValues.inventoryId = lotId;

          // eslint-disable-next-line no-await-in-loop
          const success = await apiCall(formValues);

          if (!success) {
            anyFailed = true;
          }
        }

        if (!anyFailed) {
          refresh();
          ToastMessage.success('Inventory adjusted successfully');
          onClose();
        }
      }
    }
    setLoading(false);
  };

  const apiCall = async formValues => {
    try {
      const payload = {
        adjustmentType: formValues.adjustmentType,
        quantity: formValues.quantity,
        note: formValues.note,
      };

      if (formValues.adjustmentType === InventoryAdjustmentTypes.transfer) {
        payload.destinationLocationId = formValues.destinationLocationId;
      }

      await InventoryService.updateInventory(formValues.inventoryId, payload);
      return true;
    } catch (error) {
      ToastMessage.apiError(error);
      return false;
    }
  };

  useEffect(() => {
    if (CommonUtility.isValidObject(item)) {
      const lotValue = {};
      const resetValues = {
        note: '',
        quantity: 0,
        inventoryId: '',
      };

      if (!Number.isNaN(item.lotIndex) && item.lotIndex !== -1) {
        resetValues.inventoryId =
          item.locationSummary[item.lotIndex]?.inventoryId;

        lotValue[item.locationSummary[item.lotIndex]?.inventoryId] = true;
      }

      if (item?.transferLot) {
        resetValues.adjustmentType = InventoryAdjustmentTypes.transfer;
      }
      if (item?.disposeLot) {
        resetValues.adjustmentType = InventoryAdjustmentTypes.decrease;
      }
      reset(resetValues);
      setLotValue(lotValue);
      return;
    }

    reset({
      note: '',
      quantity: 0,
      inventoryId: '',
      adjustmentType: '',
    });

    setLotValue({});
  }, [item]);

  const lotOptions = useMemo(() => {
    if (CommonUtility.isValidObject(item)) {
      return (
        item.locationSummary?.map(location => ({
          text: location.lotNumber,
          value: location.inventoryId,
        })) || []
      );
    }
    return [];
  }, [item]);

  const currentQuantity = useMemo(() => {
    if (inventoryId && CommonUtility.isValidObject(item)) {
      const location = item?.locationSummary.find(
        x => x.inventoryId === inventoryId,
      );
      if (location) {
        return location.quantity?.available;
      }
    }
    return 0;
  }, [inventoryId, item]);

  const newInventory = useMemo(() => {
    if (!quantity || !currentQuantity || !adjustmentType)
      return currentQuantity || 0;
    if (adjustmentType === InventoryAdjustmentTypes.increase) {
      return Number(currentQuantity || 0) + Number(quantity || 0);
    }

    return Number(currentQuantity || 0) - Number(quantity || 0);
  }, [quantity, adjustmentType, currentQuantity]);

  const transferView = useMemo(
    () => adjustmentType === InventoryAdjustmentTypes.transfer,
    [adjustmentType],
  );

  const disposeView = useMemo(
    () => adjustmentType === InventoryAdjustmentTypes.decrease,
    [adjustmentType],
  );

  const fetchDestinationOptions = async () => {
    try {
      setDestinationLoading(true);
      const payload = {
        warehouseId,
        items: [
          {
            productId: item.productId,
            inventoryType: 'case',
            quantity: {
              total: currentQuantity,
              available: newInventory,
            },
          },
        ],
      };
      const res = await InventoryService.getInventoryProposals(payload);
      const options = (res?.[0]?.proposedLocations || [])?.map(location => ({
        text: location?.location?.code,
        value: location?.location?._id,
        key: location?.location?._id,
      }));
      setDestinationOptions(options);
    } catch (error) {
      ToastMessage.apiError(error);
      setDestinationOptions([]);
    } finally {
      setDestinationLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (adjustmentType !== InventoryAdjustmentTypes.transfer || !inventoryId)
        return;
      fetchDestinationOptions();
    },
    [adjustmentType, inventoryId],
    300,
  );

  const onToggleCheck = (key, value) => {
    setLotValue(lotValue => ({
      ...lotValue,
      [key]: value,
    }));
  };

  return (
    <SkupremeModal
      size="mini"
      title="Inventory Adjustment"
      onClose={onClose}
      open={open}
    >
      <div className="d-flex align-items-center mb-3">
        <ItemImage
          imageKey="p_images"
          displayImageKey="display_image"
          item={item?.product}
          size="mini"
        />
        <div className="d-flex ml-2">
          <MuteText>SKU</MuteText>
          <b className="ml-2">{item?.product?.p_sku}</b>
        </div>
      </div>
      <Form onSubmit={handleSubmit(onConfirm)}>
        {disposeView ? (
          <div className="mb-2 mt-4">
            <b>Select Lots for Disposal</b>
            {item?.locationSummary?.map(location => (
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <StyledCheckbox
                    checked={lotValue[location?.inventoryId]}
                    onChange={(e, { checked }) =>
                      onToggleCheck(location.inventoryId, checked)
                    }
                  />
                  <span className="ml-2">{location.lotNumber}</span>
                </div>
                <MuteText>{location.location.code}</MuteText>
              </div>
            ))}
          </div>
        ) : (
          <DropdownFormField
            control={control}
            name="inventoryId"
            error={errors.inventoryId}
            hint={errors.inventoryId?.message}
            label="Lot"
            options={lotOptions}
          />
        )}
        <DropdownFormField
          control={control}
          name="adjustmentType"
          error={errors.adjustmentType}
          hint={errors.adjustmentType?.message}
          label="Type"
          options={adjusmentOptions}
        />
        {transferView && (
          <DropdownFormField
            control={control}
            name="destinationLocationId"
            error={errors.destinationLocationId}
            hint={errors.destinationLocationId?.message}
            label="Destination Location"
            options={destinationOptions}
            loading={destinationLoading}
          />
        )}
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <MuteText>Quantity</MuteText>
            {inventoryId && (
              <div>
                <MuteText>Current Inventory</MuteText>
                <span className="ml-1">{currentQuantity}</span>
              </div>
            )}
          </div>
          <NumberFormField
            placeholder="Quantity"
            control={control}
            name="quantity"
            error={errors.quantity}
            hint={errors.quantity?.message}
            fieldClass="mb-0"
          />
          <div className="d-flex justify-content-end mt-1">
            {inventoryId && (
              <>
                <MuteText>New Inventory</MuteText>
                <span className="ml-1">{newInventory}</span>
              </>
            )}
          </div>
        </div>
        <ControlledTextAreaFormField
          control={control}
          placeholder="Additional Note"
          label="Note"
          name="note"
          error={errors.note}
          hint={errors.note?.message}
          rows="3"
        />
        <div className="d-flex justify-content-end mt-4">
          <HoverBorderButton type="button" disabled={loading} onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton loading={loading} type="submit" className="ml-2">
            Update
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
