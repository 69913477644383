import moment from 'moment';

export const DateFormat = {
  date: 'MM/DD/YYYY',
  dateTime: 'MM/DD/YYYY hh:mm A',
  yearMonthDate: 'YYYY-MM-DD',
  dashSmallYearMonthSmallDate: 'yyyy-MM-dd',
  monthDateYear: 'MM/DD/YYYY',
  month: 'MMM DD, YYYY',
  barChartMonth: 'MMM DD, YY',
  localeMonthDate: 'MMM DD',
  localeMonthDateSpaceYear: 'MMM DD YYYY',
  dashDateMonthYear: 'DD-MM-YYYY',
  dashMonthDateYear: 'MM-DD-YYYY',
  dashMonthDateSmallYear: 'MM-DD-yyyy',
  dashMonthYear: 'MM-YYYY',
  dateSpreadsheet: 'M.D.YY',
  dotMonthDateYear: 'MM.DD.YYYY',
  dateBatchPopup: 'MMDDYY hh:mm',
  LL: 'LL',
  LT: 'LT',
  day: 'ddd',
  timestamp: 'x',
  HoursMinutes12: 'hh:mm A',
};

export class DateUtility {
  static formatDate = (date, format = DateFormat.dateTime) => {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  };

  static difference = (date1, date2, unit = 'days') =>
    moment(date2).diff(date1, unit);

  static noOfDays = (date1, date2) => {
    if (!date2) {
      date2 = moment().utc();
    }
    const days = DateUtility.difference(date1, date2);
    return days;
  };

  static duration = (date1, date2) =>
    moment.duration(moment(date2).diff(date1));

  static hourMinuteTime = (date1, date2, detail = 2) => {
    if (!date2) {
      date2 = moment().utc();
    }
    const diff = DateUtility.duration(date1, date2);
    const days = DateUtility.difference(date1, date2);
    const hours = diff.get('hours');
    const mins = diff.get('minutes');
    const seconds = diff.get('seconds');
    let temp = [];
    if (days > 0) {
      temp.push(`${days} Days`);
    }
    if (hours > 0) {
      temp.push(`${hours} Hrs`);
    }
    if (mins > 0) {
      temp.push(`${mins} Mins`);
    }
    if (seconds > 0) {
      temp.push(`${seconds < 0 ? 1 : seconds} Secs`);
    }
    return temp.slice(0, detail).join(' ');
  };
}
