export class StylingUtility {
  static getLowerOpacityColor(color, opacity = 0.1) {
    if (opacity < 0 || opacity > 1) {
      throw new Error('Opacity must be a value between 0 and 1.');
    }

    let r;
    let g;
    let b;

    if (color.startsWith('#')) {
      // Convert hex to RGB
      const hex = color.slice(1);
      if (hex.length === 3) {
        // Short hex format (#RGB)
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
      } else if (hex.length === 6) {
        // Full hex format (#RRGGBB)
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
      } else {
        throw new Error('Invalid hex color format.');
      }
    } else if (color.startsWith('rgb')) {
      // Extract RGB values from the RGB string
      const rgbValues = color.match(/\d+/g);
      if (!rgbValues || rgbValues.length < 3) {
        throw new Error('Invalid RGB color format.');
      }
      [r, g, b] = rgbValues.map(Number);
    } else {
      throw new Error('Invalid color format. Please use a hex or RGB color.');
    }

    // Return RGBA color with specified opacity
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
