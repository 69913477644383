import { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { SkupremeModal } from '../../../components';
import { HoverBorderButton, MuteText, StyledButton } from '../../../elements';
import { ItemAllocationToteDetails } from '../../../hooks/tote';
import { CommonUtility, ToastMessage, WaveService } from '../../../utility';

export function TotePopup({
  item,
  open,
  onClose,
  warehouse,
  afterToteCreation,
}) {
  const { data } = ItemAllocationToteDetails(item?.id);
  const [toteName, setToteName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setToteName('');
  }, [open]);

  const tote = CommonUtility.isValidObject(data?.tote) ? data?.tote : null;

  const createTote = async () => {
    try {
      setLoading(true);
      if (!toteName) {
        ToastMessage.error('Tote Number is required');
        return;
      }
      const payload = {
        warehouseId: warehouse?._id,
        toteNumber: toteName,
      };
      const res = await WaveService.createTote(payload);
      afterToteCreation(item, res._id);
      onClose();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const assignToExistingTote = () => {
    onClose();
    afterToteCreation(item, tote._id);
  };

  return (
    <SkupremeModal
      title={
        <div className="d-flex">
          Assign Tote from
          <div className="d-flex ml-1">
            <MuteText>Warehouse</MuteText>
            <span className="ml-1">{warehouse?.name}</span>
          </div>
        </div>
      }
      size="tiny"
      open={open}
      onClose={onClose}
    >
      <div className="my-4">
        {tote
          ? 'This item will be assinged to an exising Tote:'
          : 'No tote available create a new Tote'}
        <div className="mt-1">
          <MuteText className="mr-1">Tote Number</MuteText>
          {tote ? (
            tote.toteNumber
          ) : (
            <Input
              fluid
              placeholder="TOTE-X0X1"
              value={toteName}
              onChange={e => setToteName(e.target.value)}
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <HoverBorderButton disabled={loading} type="button" onClick={onClose}>
          Cancel
        </HoverBorderButton>
        {tote ? (
          <StyledButton
            loading={loading}
            type="button"
            onClick={assignToExistingTote}
            className="ml-2"
          >
            Confirm
          </StyledButton>
        ) : (
          <StyledButton
            loading={loading}
            type="button"
            onClick={createTote}
            className="ml-2"
          >
            Create Tote and Assign
          </StyledButton>
        )}
      </div>
    </SkupremeModal>
  );
}
