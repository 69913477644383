import { useEffect, useState } from 'react';
import { RackService } from '../utility';

export const RackTypesHook = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { types } = await RackService.types();
      const options = Object.keys(types || {}).map(zoneKey => {
        const zoneType = types[zoneKey];
        return {
          text: zoneType.description || zoneKey,
          value: zoneKey,
          key: zoneKey,
        };
      });
      setData(options);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const RackDetailHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { rack, levels } = await RackService.getById(id);
      setData(rack);
      setLocationData(levels);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refreshData: fetchData, locationData };
};

export const RackPresetsHook = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { presets } = await RackService.presets();
      const options = presets.map(preset => ({
        text: preset.name,
        value: preset._id,
        key: preset._id,
        item: preset,
      }));
      options.unshift({
        text: 'Create a New Rack',
        key: '-1',
        value: '-1',
      });
      setData(options);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};
