import { useState } from 'react';
import { AppTable, LoaderBar } from '../../../../../../components';
import { ToteListWarehouseHook } from '../../../../../../hooks/tote';
import { SortedToteRow } from '../../../../../../page-components';
import { OrderService, ToastMessage } from '../../../../../../utility';

export function ToteTab({ warehouseId }) {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = ToteListWarehouseHook(warehouseId);
  const [processing, setProcessing] = useState(false);

  const columns = [
    {
      text: 'Tote Number',
      key: 'tote-number',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Allocated At',
      key: 'updatedAt',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Contents',
      key: 'status',
      textAlign: 'left',
      width: 2,
    },
    {
      text: '',
      key: 'actions',
      width: 3,
    },
  ];

  const fulfillTote = async item => {
    try {
      setProcessing(true);

      const payload = {
        toteId: item.id,
      };
      await OrderService.fulfillTote(item.orderId, payload);
      ToastMessage.success('Order marked as fulfilled Successfully');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="position-relative">
      {processing && <LoaderBar />}

      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={<SortedToteRow fulfillTote={fulfillTote} />}
      />
    </div>
  );
}
