import styled from 'styled-components';
import { useMemo } from 'react';
import { File, MapPin, Waves } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import {
  Image,
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
} from 'semantic-ui-react';
import { ItemImage, OrganisationAvatar } from '../../../components';
import { MuteText, ShadowBox } from '../../../elements';
import { CommonUtility } from '../../../utility/common';
import { OrderStatus } from '../../../utility';

const ShadowBoxHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.box};
  margin: 0;
  padding: 0 1.3em;
  padding-bottom: 1em;
`;

const Header = styled.div`
  font-size: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  padding-bottom: 10px;
  margin-top: 10px;
`;

export function LabelDetails({ data, loading }) {
  return (
    <ShadowBox className="col-6 p-0">
      {loading ? (
        <div className="p-4">
          <Placeholder fluid>
            <PlaceholderParagraph>
              <PlaceholderLine />
            </PlaceholderParagraph>
            <PlaceholderParagraph>
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
            </PlaceholderParagraph>
          </Placeholder>
        </div>
      ) : (
        <>
          <ShadowBoxHeader className="pt-4">
            <b>Labels</b>
          </ShadowBoxHeader>
          <Header className="row">
            <div className="col-4">
              <MuteText>Carrier</MuteText>
            </div>
            <div className="col-2">
              <MuteText>Tracking</MuteText>
            </div>
            <div className="col-2">
              <MuteText>Dimensions</MuteText>
            </div>
            <div className="col-4">
              <MuteText>Label</MuteText>
            </div>
          </Header>
          {data?.labels?.map((label, index) => (
            <LabelRow
              itemsData={data?.items}
              totalLabels={data?.labels?.length}
              item={label}
              index={index}
            />
          ))}
          <div className="mb-2" />
        </>
      )}
    </ShadowBox>
  );
}

const FileContainer = styled.a`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.box};
  padding: 10px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

const TrackingId = styled.span`
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
`;

const CarrierLogo = styled(Image)`
  width: 30px;
  height: 40px;
  object-fit: contain;
  margin-right: 7px;
`;

function LabelRow({ item, itemsData, totalLabels, index }) {
  const isLastLabel = totalLabels === index + 1;

  const images = {
    dhl: CommonUtility.getCdnLink('integrations/dhl.png'),
    usps: CommonUtility.getCdnLink('integrations/usps-small.webp'),
    ups: CommonUtility.getCdnLink('integrations/ups.png'),
    fedex: CommonUtility.getCdnLink('integrations/fedex.png'),
  };

  return (
    <>
      <div className="row py-2">
        <div className="col-4 d-flex">
          {images[item.carrier_code] ? (
            <CarrierLogo src={images[item.carrier_code]} />
          ) : (
            <OrganisationAvatar
              name={CommonUtility.removeUnderscoreAndCapitalize(
                item.carrier_code,
              )}
            />
          )}
          <div className="d-flex flex-column">
            <b>
              {CommonUtility.removeUnderscoreAndCapitalize(item.carrier_code)}
            </b>
            <MuteText>
              {CommonUtility.removeUnderscoreAndCapitalize(item.service.value)}
            </MuteText>
          </div>
        </div>
        <div className="col-2 pt-2">
          <TrackingId>{item.tracking_id}</TrackingId>
        </div>
        <div className="col-2 pt-2">
          <div className="d-flex justify-content-left flex-wrap">
            <span>
              {CommonUtility.roundNumber(item?.dimensions?.length?.value)}
            </span>
            <MuteText className="mx-1">x</MuteText>
            <span>
              {CommonUtility.roundNumber(item?.dimensions?.width?.value)}
            </span>
            <MuteText className="mx-1">x</MuteText>
            <span>
              {CommonUtility.roundNumber(item?.dimensions?.height?.value)}
            </span>
            <MuteText className="ml-1">
              ({item?.dimensions?.width?.unit}.)
            </MuteText>
          </div>
        </div>
        <div className="col-4">
          <FileContainer
            className="mr-4"
            href={item.url}
            download
            target="_blank"
          >
            <File className="mr-2" size={18} />
            <span>View Label</span>
          </FileContainer>
        </div>
      </div>
      {item?.items?.map((itemDetail, index) => (
        <ItemRow
          showBorder={!isLastLabel && item.items?.length === index + 1}
          item={itemDetail}
          itemsData={itemsData}
        />
      ))}
    </>
  );
}

const ItemTitle = styled.div`
  b {
    max-width: 15vw;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ItemContainer = styled.div`
  background: ${({ theme }) => theme.colors.box};
  padding: 10px 0;
`;

function ItemRow({ item, itemsData }) {
  const itemDetail = useMemo(
    () => itemsData.find(x => x.item_id === item.item_id),
    [item, itemsData],
  );

  return (
    <ItemContainer className="row">
      <div className="col-6 pl-4">
        <div className="d-flex ml-4 pl-4">
          <ItemImage item={itemDetail} size="mini" />
          <ItemTitle className="ml-2">
            <b>{itemDetail?.title}</b>
            <div>
              <MuteText>SKU</MuteText>
              <span className="ml-1">{itemDetail?.sku}</span>
            </div>
          </ItemTitle>
        </div>
      </div>
      <div className="col-2 pt-2">
        {CommonUtility.currencyFormat(itemDetail?.itemPrice)}
      </div>
      <div className="last-cell col-4 pt-2">
        <span className="ml-1">x {item?.qty}</span>
      </div>
    </ItemContainer>
  );
}

const PickingItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
`;

const Border = styled.div`
  background: ${({ theme }) => theme.colors.box};
  height: 1px;
  width: 100%;
  margin: 10px 0;
`;

const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export function PickingList({ data, loading }) {
  const pickingList = useMemo(() => {
    if (data?.states?.[0] === OrderStatus.pendingFulfillment) {
      return (
        data?.items?.filter(x => x.state === OrderStatus.pendingFulfillment) ||
        []
      );
    }
    return data?.items;
  }, [data]);

  if (!CommonUtility.isValidArray(pickingList)) {
    return <></>;
  }

  return (
    <div className="col-6 pl-0">
      <ShadowBox className="p-0">
        {loading ? (
          <div className="p-4">
            <Placeholder fluid>
              <PlaceholderParagraph>
                <PlaceholderLine />
              </PlaceholderParagraph>
              <PlaceholderParagraph>
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
              </PlaceholderParagraph>
            </Placeholder>
          </div>
        ) : (
          <>
            <ShadowBoxHeader className="pt-4">
              <b>Pick List</b>
            </ShadowBoxHeader>
            <Header className="row">
              <MuteText className="col-6">Item</MuteText>
              <MuteText className="col-3">Location</MuteText>
              <MuteText className="col-3">Wave</MuteText>
            </Header>
            <PickingItemsContainer>
              {pickingList.map((item, index) => (
                <>
                  <div className="row">
                    <div className="col-6 d-flex align-items-start">
                      <ItemImage size="mini" item={item} />
                      <ItemTitle className="ml-2">
                        <b>{item.title}</b>
                        <div className="d-flex">
                          <MuteText>SKU</MuteText>
                          <span className="ml-2">{item.sku}</span>
                        </div>
                      </ItemTitle>
                    </div>
                    <LocationsContainer className="col-3">
                      {CommonUtility.isValidArray(item.inventory) &&
                        item.inventory.map(inventory => (
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                              <MapPin className="mr-2" />
                              <span>{inventory?.location?.code}</span>
                            </div>
                            <div className="d-flex">
                              <MuteText>Available</MuteText>
                              <span className="ml-2">
                                {inventory.quantity?.available}
                              </span>
                            </div>
                          </div>
                        ))}
                    </LocationsContainer>
                    <LocationsContainer className="col-3">
                      {CommonUtility.isValidArray(item.allocations) &&
                        item.allocations.map(allocation => (
                          <div className="d-flex flex-column">
                            {allocation?.waveNumber ? (
                              <Link
                                to={`/app/fulfill/waves/${allocation.waveId}`}
                              >
                                <div className="d-flex align-items-center">
                                  <Waves className="mr-2" />
                                  <span>{allocation?.waveNumber}</span>
                                </div>
                              </Link>
                            ) : (
                              <MuteText>Not able to be added to wave</MuteText>
                            )}
                            <div className="d-flex">
                              <MuteText>Quantity</MuteText>
                              <span className="ml-2">
                                {allocation.quantity}
                              </span>
                            </div>
                          </div>
                        ))}
                    </LocationsContainer>
                  </div>
                  {index + 1 !== data?.items.length && <Border />}
                </>
              ))}
            </PickingItemsContainer>
            <div className="pb-2" />
          </>
        )}
      </ShadowBox>
    </div>
  );
}
