import { TableCell, TableRow } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FlowArrow } from '@phosphor-icons/react';
import { DateFormat, DateUtility, theme } from '../../utility';
import { ColorText, MuteText } from '../../elements';
import { WaveStatusRenderer } from './common';

function OrderTime({ time, isEnabled }) {
  let timeColor = '';
  const noOfDays = DateUtility.noOfDays(time);
  if (isEnabled) {
    if (noOfDays < 1) {
      timeColor = theme.colors.green;
    } else if (noOfDays <= 2) {
      timeColor = theme.colors.yellow;
    } else if (noOfDays <= 5) {
      timeColor = theme.colors.orange;
    } else if (noOfDays > 5) {
      timeColor = theme.colors.danger;
    }
  }
  return (
    <ColorText color={timeColor}>
      <b>{DateUtility.hourMinuteTime(time)}</b>
    </ColorText>
  );
}

export function WaveRow({ item }) {
  return (
    <TableRow>
      <TableCell>
        <Link to={`/app/fulfill/waves/${item._id}`}>{item.waveNumber}</Link>
      </TableCell>
      <TableCell>
        <WaveStatusRenderer status={item.status} />
      </TableCell>
      <TableCell>
        <OrderTime time={item.createdAt} isEnabled />
        <div>
          <MuteText>
            {DateUtility.formatDate(item.createdAt, DateFormat.barChartMonth)}
          </MuteText>
        </div>
      </TableCell>
      <TableCell>{item.warehouseId?.name}</TableCell>
      <TableCell>
        <div className="text-nowrap d-flex">
          <FlowArrow size={17} className="mr-1" /> {item.waveRuleId?.name}
        </div>
      </TableCell>
      <TableCell>
        <div>
          <div className="d-flex">
            <MuteText>Items</MuteText>
            <span className="ml-2">{item.metadata.itemCount}</span>
          </div>
          <div className="d-flex">
            <MuteText>Starting Rack</MuteText>
            <span className="ml-2">{item.metadata.startingRack?.code}</span>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
