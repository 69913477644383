import { CommonUtility } from '../common';
import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Client extends CRUDService {
  constructor() {
    super(APIPath.organizationDashboard);
  }

  export(params) {
    const url = `${this.url}/export?${CommonUtility.objectToParams(params)}`;
    return BaseService.get(url);
  }
}

const ClientService = new Client();
Object.freeze(ClientService);
export { ClientService };
