import { useFieldArray, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PencilSimple, Plus } from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import {
  ControlledTextAreaFormField,
  ControlledTextFormField,
  DangerText,
  HiddenFileAccepter,
  HoverBorderButton,
  ImageContainer,
  ImagePreview,
  MuteText,
  ShadowBox,
} from '../../../elements';
import { ImageCropModal } from '../../../components/ImageCropModal';
import { UploadService } from '../../../utility/services/upload';
import {
  BYTES_FOR_ONE_MB,
  CommonConstant,
  CommonUtility,
  initialImageCrop,
  OrganizationsService,
  ToastMessage,
} from '../../../utility';
import { useAuth } from '../../../contexts/auth';
import { CustomFields } from '../../../page-components/settings';
import { AlertFooterBar } from '../../../elements/FooterAlert';
import { OrganizationIntegrationHook } from '../../../hooks';
import { LoaderBar } from '../../../components/LoaderBar';

const WarehouseSchema = yup.object().shape({
  name: yup.string(),
  displayName: yup.string().required('Display Name is required'),
  description: yup.string(),
  customFields: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Label is Required'),
        placeholder: yup.string().optional(),
        required: yup.boolean(),
        type: yup.string().required('Type is Required'),
        validation: yup
          .object()
          .shape({
            pattern: yup.string().optional(),
            min: yup
              .number()
              .transform((_, val) => (!isNaN(val) ? Number(val) : null))
              .optional()
              .nullable(),
            max: yup
              .number()
              .transform((_, val) => (!isNaN(val) ? Number(val) : null))
              .optional()
              .nullable(),
            message: yup.string().optional(),
          })
          .optional(),
        options: yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string().required('Label is Required'),
              value: yup.string().required('Value is Required'),
            }),
          )
          .when('type', {
            is: 'radio',
            then: schema =>
              schema
                .min(1, 'Options are required for radio type')
                .typeError('Options are required for radio type'),
            otherwise: schema => schema.optional(),
          }),
      }),
    )
    .optional(),
});

export function GeneralSettingsTab() {
  const [image, setImage] = useState();
  const [fileError, setFileError] = useState('');
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const {
    data: organizationData,
    refresh,
    loading: orgLoading,
  } = OrganizationIntegrationHook(user.organizationId);

  const {
    formState: { errors, dirtyFields },
    control,
    reset,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(WarehouseSchema),
  });

  const {
    append,
    fields: customFields,
    remove,
  } = useFieldArray({
    control,
    name: 'customFields',
  });

  useEffect(() => {
    if (CommonUtility.isValidObject(organizationData.integrationMetadata)) {
      resetData(organizationData);
    }
  }, [organizationData]);

  const resetData = organizationData => {
    setFiles([]);
    setDefaultImage(organizationData?.integrationMetadata?.logo?.url);
    const resetObj = {
      displayName: organizationData?.integrationMetadata?.displayName || '',
      description: organizationData?.integrationMetadata?.description || '',
      customFields: [],
    };

    resetObj.customFields =
      Object.values(
        organizationData?.integrationMetadata?.customFields || {},
      ).map(customField => customField) || [];

    reset(resetObj);
  };

  const onCropModalConfirm = files => {
    setFiles(files);
    setImage(files[0]);
  };

  const fileRef = useRef(null);
  const acceptFile = () => {
    fileRef?.current?.click();
  };

  const handleFileChange = event => {
    const acceptedFiles = event.target.files;
    if (!acceptedFiles?.length) {
      return;
    }

    const maxSize = 2 * BYTES_FOR_ONE_MB;
    if (acceptedFiles[0].size > maxSize) {
      ToastMessage.error(
        `File size should be less than ${maxSize / BYTES_FOR_ONE_MB} MB.`,
      );
      return;
    }

    setImage(acceptedFiles[0]);
    setIsModalOpen(true);
    setFileError('');
  };

  const removeField = index => {
    remove(index);
  };

  const upload = async () => {
    const newFiles = files;
    try {
      if (newFiles.length > 0) {
        const file = newFiles[0];
        const { uploadUrl, key, bucketName } =
          await OrganizationsService.logoSignedUrl({
            type: 'image',
            extension: file.name?.split('.').pop(),
          });
        await UploadService.media(uploadUrl, file, file.type);
        return {
          key,
          bucketName,
        };
      }
    } catch (error) {
      return {
        key: null,
        bucket_name: null,
      };
    }
  };

  const onSubmit = async formValues => {
    try {
      setLoading(true);
      const logo = await upload();
      let payload = {
        integrationMetadata: {
          description: formValues.description,
          displayName: formValues.displayName,
        },
      };

      payload.integrationMetadata.customFields = {};
      formValues.customFields?.forEach(customField => {
        payload.integrationMetadata.customFields[
          CommonUtility.toCamelCase(customField.label)
        ] = customField;
      });

      if (logo) {
        payload.integrationMetadata.logo = logo;
      }
      payload = CommonUtility.removeEmptyFields(payload);
      await OrganizationsService.udpateIntegration(
        user.organizationId,
        payload,
      );
      refresh();
      ToastMessage.success('Integration settings updated.');
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const addField = () => {
    append({
      type: 'string',
      label: '',
      placeholder: '',
      required: false,
    });
  };

  return (
    <div className="py-3 position-relative">
      {orgLoading && <LoaderBar />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ShadowBox className="mt-4">
          <h3>Integration Settings</h3>
          <b>Information</b>
          <Form.Group className="mt-2 mb-1">
            <ControlledTextFormField
              error={errors.displayName}
              hint={errors.displayName?.message}
              label="Display Name"
              name="displayName"
              control={control}
              required
              width={4}
            />
            <Form.Field>
              <MuteText>Profile Picture</MuteText>
              <div className="d-flex mt-1">
                <div className="d-flex flex-column">
                  <ImageContainer>
                    <ImagePreview
                      src={
                        (image && URL.createObjectURL(image)) ||
                        defaultImage ||
                        CommonConstant.defaultImage
                      }
                    />
                  </ImageContainer>
                  {fileError && (
                    <DangerText className="mt-2">{fileError}</DangerText>
                  )}
                </div>
                <HiddenFileAccepter
                  onChange={handleFileChange}
                  accept="image/*"
                  ref={fileRef}
                  type="file"
                />
                <HoverBorderButton
                  type="button"
                  onClick={acceptFile}
                  className="p-2 mx-2"
                >
                  <PencilSimple size={18} />
                </HoverBorderButton>
              </div>
              <ImageCropModal
                fileName={image?.name}
                src={image && URL.createObjectURL(image)}
                altText="Profile pic"
                upload={onCropModalConfirm}
                open={isModalOpen}
                setOpen={setIsModalOpen}
                initialCrop={initialImageCrop}
                aspect={1}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <ControlledTextAreaFormField
              error={errors.description}
              hint={errors.description?.message}
              label="Description"
              name="description"
              control={control}
              rows={1}
              width={4}
            />
          </Form.Group>
          <b>User Information</b>
          <CustomFields
            errors={errors}
            customFields={customFields}
            control={control}
            removeField={removeField}
            watch={watch}
          />
          <HoverBorderButton
            type="button"
            onClick={addField}
            className="flex-btn mt-2"
          >
            <Plus className="mr-1" /> Field
          </HoverBorderButton>
        </ShadowBox>
        {(CommonUtility.isValidObject(dirtyFields) ||
          CommonUtility.isValidArray(files)) && (
          <AlertFooterBar
            loading={loading}
            message="Save Changes"
            submitButton
            cancel={() => resetData(organizationData)}
          />
        )}
      </Form>
    </div>
  );
}
