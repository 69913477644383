import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Rack extends CRUDService {
  constructor() {
    super(APIPath.racks);
  }

  types() {
    return BaseService.get(APIPath.rackTypes);
  }

  presets() {
    return BaseService.get(APIPath.rackPresets);
  }

  addLocations(data) {
    return BaseService.post(APIPath.rackLocations, data);
  }
}

const RackService = new Rack();
Object.freeze(RackService);
export { RackService };
