import styled from 'styled-components';

const HeightDimension = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.grey};
  width: 1px;
  height: 30px;
  left: 5px;
`;

const TopSlash = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.grey};
  height: 1px;
  width: 10px;
  left: 0px;
  top: 7px;
`;

const BottomSlash = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.grey};
  height: 1px;
  width: 10px;
  left: 0px;
  bottom: 7px;
`;

const HeightDimensionText = styled.span`
  font-size: 12px;
  left: 13px;
  position: absolute;
`;

export function HeightDimensionViewer({ width, measurementUnit }) {
  return (
    <>
      <TopSlash />
      <HeightDimension />
      <BottomSlash />
      <HeightDimensionText>
        {width} {measurementUnit}
      </HeightDimensionText>
    </>
  );
}

const RightSlash = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.grey};
  width: 1px;
  height: 10px;
  right: 0px;
  bottom: 3px;
`;

const LeftSlash = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.grey};
  width: 1px;
  height: 10px;
  left: 15px;
  bottom: 3px;
`;

const WidthDimension = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.grey};
  width: calc(100% - 15px);
  height: 1px;
  left: 15px;
  bottom: 7px;
`;

const WidthDimensionText = styled.div`
  font-size: 12px;
  right: 12px;
  bottom: 7px;
  position: absolute;
`;

export function WidthDimensionViewer({ height, measurementUnit }) {
  return (
    <>
      <LeftSlash />
      <WidthDimension />
      <RightSlash />
      <WidthDimensionText>
        {height} {measurementUnit}
      </WidthDimensionText>
    </>
  );
}
