import { DotsSixVertical, GridNine, Plus } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';
import { MuteText } from '../../elements';
import { ItemTypes } from './common';

const RackViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.box};
  cursor: grab;
  padding: 15px;
  width: 125px;
  min-width: 125px;
  color: black;
  border-radius: 5px;
  row-gap: 7px;
  position: relative;
  height: 125px;

  .small {
    font-size: 12px;
  }

  &.is-dragging {
    cursor: grabbing;
    opacity: 0;
  }

  &.is-over {
    background: ${({ theme }) => theme.colors.grey};
  }
`;

const GrabIcon = styled(DotsSixVertical)`
  position: absolute;
  right: 5px;
  top: 5px;
`;

export function RackBox({
  _id,
  levelCount,
  bayCount,
  color,
  code,
  index,
  onHoverSortRack,
  aisleSide,
  aisleIndex,
}) {
  const navigate = useNavigate();
  const rackRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.rack,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: hoverItem => {
      if (!rackRef.current) {
        return;
      }

      // only do sorting in row

      const dragIndex = hoverItem.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (
        dragIndex === hoverIndex &&
        aisleSide === hoverItem.aisleSide &&
        aisleIndex === hoverItem.aisleIndex
      ) {
        return;
      }

      // Perform the sort
      onHoverSortRack(
        hoverItem.aisleIndex,
        hoverItem.aisleSide,
        hoverItem.index,
        hoverIndex,
        aisleIndex,
        aisleSide,
      );

      // Update the drag item's index
      hoverItem.index = hoverIndex;
      hoverItem.aisleSide = aisleSide;
      hoverItem.aisleIndex = aisleIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.rack,
      item: () => ({ _id, aisleSide, aisleIndex, index }),
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
      isDragging: monitor => monitor.getItem()._id === _id,
    }),
    [_id, index, aisleSide, aisleIndex],
  );

  drag(drop(rackRef));

  const onClick = () => {
    navigate(`racks/${_id}`);
  };

  return (
    <RackViewContainer
      onClick={onClick}
      ref={rackRef}
      className={`${isDragging ? 'is-dragging' : ''}`}
      data-handler-id={handlerId}
    >
      <GrabIcon size={18} />
      <GridNine color={color} />
      <span>{code}</span>
      <div className="d-flex justify-content-between">
        <MuteText className="small">{levelCount} Levels</MuteText>
        <MuteText className="ml-2 small">{bayCount} Bays</MuteText>
      </div>
    </RackViewContainer>
  );
}

const AddRackBox = styled(RackViewContainer)`
  background: none;
  border: 2px dashed ${({ theme }) => theme.colors.box};
  color: ${({ theme }) => theme.colors.grey};
  user-select: none;
`;

export function AddRack(props) {
  return (
    <AddRackBox {...props}>
      <div className="d-flex align-items-center justify-content-center">
        <Plus className="mr-1" /> Rack
      </div>
    </AddRackBox>
  );
}
