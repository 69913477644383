import { Form } from 'semantic-ui-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ItemImage, SkupremeModal } from '../../components';
import {
  ControlledTextAreaFormField,
  DropdownFormField,
  HoverBorderButton,
  MuteText,
  NumberFormField,
  StyledButton,
} from '../../elements';
import {
  CommonUtility,
  InventoryAdjustmentTypes,
  InventoryService,
  ToastMessage,
} from '../../utility';
import { useDebouncedEffect } from '../../hooks';

const ItemSchema = yup.object().shape({
  inventoryId: yup.string().required('Select a Lot'),
  adjustmentType: yup.string().required('Adjustment type is required'),
  quantity: yup.number().positive('Quantiy is required'),
  note: yup.string().required('Note is required'),
  destinationLocationId: yup
    .string()
    .when('adjustmentType', (adjustmentType, schema) => {
      if (adjustmentType?.includes(InventoryAdjustmentTypes.transfer)) {
        return schema
          .required('Destination is required')
          .nonNullable('Destination is required');
      }
      return schema.optional().nullable();
    }),
});

const adjusmentOptions = [
  {
    text: 'Increase',
    value: InventoryAdjustmentTypes.increase,
    key: InventoryAdjustmentTypes.increase,
  },
  {
    text: 'Decrease',
    value: InventoryAdjustmentTypes.decrease,
    key: InventoryAdjustmentTypes.decrease,
  },
  {
    text: 'Transfer',
    value: InventoryAdjustmentTypes.transfer,
    key: InventoryAdjustmentTypes.transfer,
  },
];

export function InventoryAdjustmentPopup({
  onClose,
  item,
  refresh,
  open,
  warehouseId,
}) {
  const [loading, setLoading] = useState(false);
  const [destinationLoading, setDestinationLoading] = useState(false);
  const [destinationOptions, setDestinationOptions] = useState([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(ItemSchema),
    shouldUnregister: true,
    mode: 'onChange',
  });

  const [inventoryId, quantity, adjustmentType] = watch([
    'inventoryId',
    'quantity',
    'adjustmentType',
  ]);

  const onConfirm = async formValues => {
    try {
      setLoading(true);
      const payload = {
        adjustmentType: formValues.adjustmentType,
        quantity: formValues.quantity,
        note: formValues.note,
      };

      if (formValues.adjustmentType === InventoryAdjustmentTypes.transfer) {
        payload.destinationLocationId = formValues.destinationLocationId;
      }

      await InventoryService.updateInventory(formValues.inventoryId, payload);
      refresh();
      ToastMessage.success('Inventory adjusted successfully');
      onClose();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (CommonUtility.isValidObject(item)) {
      const resetValues = {
        note: '',
        quantity: 0,
        inventoryId: '',
      };

      if (!Number.isNaN(item.lotIndex) && item.lotIndex !== -1) {
        resetValues.inventoryId =
          item.locationSummary[item.lotIndex]?.inventoryId;
      }

      if (item?.transferLot) {
        resetValues.adjustmentType = InventoryAdjustmentTypes.transfer;
      }
      reset(resetValues);
      return;
    }

    reset({
      note: '',
      quantity: 0,
      inventoryId: '',
      adjustmentType: '',
    });
  }, [item]);

  const lotOptions = useMemo(() => {
    if (CommonUtility.isValidObject(item)) {
      return (
        item.locationSummary?.map(location => ({
          text: location.lotNumber,
          value: location.inventoryId,
        })) || []
      );
    }
    return [];
  }, [item]);

  const currentQuantity = useMemo(() => {
    if (inventoryId && CommonUtility.isValidObject(item)) {
      const location = item?.locationSummary.find(
        x => x.inventoryId === inventoryId,
      );
      if (location) {
        return location.quantity?.available;
      }
    }
    return 0;
  }, [inventoryId, item]);

  const newInventory = useMemo(() => {
    if (!quantity || !currentQuantity || !adjustmentType)
      return currentQuantity || 0;
    if (adjustmentType === InventoryAdjustmentTypes.increase) {
      return Number(currentQuantity || 0) + Number(quantity || 0);
    }

    return Number(currentQuantity || 0) - Number(quantity || 0);
  }, [quantity, adjustmentType, currentQuantity]);

  const transferView = useMemo(
    () => adjustmentType === InventoryAdjustmentTypes.transfer,
    [adjustmentType],
  );

  const fetchDestinationOptions = async () => {
    try {
      setDestinationLoading(true);
      const payload = {
        warehouseId,
        items: [
          {
            productId: item.productId,
            inventoryType: 'case',
            quantity: {
              total: currentQuantity,
              available: newInventory,
            },
          },
        ],
      };
      const res = await InventoryService.getInventoryProposals(payload);
      const options = (res?.[0]?.proposedLocations || [])?.map(location => ({
        text: location?.location?.code,
        value: location?.location?._id,
        key: location?.location?._id,
      }));
      setDestinationOptions(options);
    } catch (error) {
      ToastMessage.apiError(error);
      setDestinationOptions([]);
    } finally {
      setDestinationLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (adjustmentType !== InventoryAdjustmentTypes.transfer || !inventoryId)
        return;
      fetchDestinationOptions();
    },
    [adjustmentType, inventoryId],
    300,
  );

  return (
    <SkupremeModal
      size="mini"
      title="Inventory Adjustment"
      onClose={onClose}
      open={open}
    >
      <div className="d-flex align-items-center mb-3">
        <ItemImage
          imageKey="p_images"
          displayImageKey="display_image"
          item={item?.product}
          size="mini"
        />
        <div className="d-flex ml-2">
          <MuteText>SKU</MuteText>
          <b className="ml-2">{item?.product?.p_sku}</b>
        </div>
      </div>
      <Form onSubmit={handleSubmit(onConfirm)}>
        <DropdownFormField
          control={control}
          name="inventoryId"
          error={errors.inventoryId}
          hint={errors.inventoryId?.message}
          label="Lot"
          options={lotOptions}
        />
        <DropdownFormField
          control={control}
          name="adjustmentType"
          error={errors.adjustmentType}
          hint={errors.adjustmentType?.message}
          label="Type"
          options={adjusmentOptions}
        />
        {transferView && (
          <DropdownFormField
            control={control}
            name="destinationLocationId"
            error={errors.destinationLocationId}
            hint={errors.destinationLocationId?.message}
            label="Destination Location"
            options={destinationOptions}
            loading={destinationLoading}
          />
        )}
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <MuteText>Quantity</MuteText>
            {inventoryId && (
              <div>
                <MuteText>Current Inventory</MuteText>
                <span className="ml-1">{currentQuantity}</span>
              </div>
            )}
          </div>
          <NumberFormField
            placeholder="Quantity"
            control={control}
            name="quantity"
            error={errors.quantity}
            hint={errors.quantity?.message}
            fieldClass="mb-0"
          />
          <div className="d-flex justify-content-end mt-1">
            {inventoryId && (
              <>
                <MuteText>New Inventory</MuteText>
                <span className="ml-1">{newInventory}</span>
              </>
            )}
          </div>
        </div>
        <ControlledTextAreaFormField
          control={control}
          placeholder="Additional Note"
          label="Note"
          name="note"
          error={errors.note}
          hint={errors.note?.message}
          rows="3"
        />
        <div className="d-flex justify-content-end mt-4">
          <HoverBorderButton type="button" disabled={loading} onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton loading={loading} type="submit" className="ml-2">
            Update
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
