import { useEffect, useMemo, useState } from 'react';
import { CalendarX, CaretDown } from '@phosphor-icons/react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { AppTable } from '../../../components/AppTable';
import { BodyContainer, HeaderBar, PageTitle } from '../../../elements';
import { InventoryListHook } from '../../../hooks/inventory';
import {
  InventoryExpirationPopup,
  InventoryExpirationRow,
} from '../../../page-components';
import { CommonUtility } from '../../../utility';
import { WarehouseListHook } from '../../../hooks';

const StyledDropdown = styled(Dropdown)`
  font-size: 20px;
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0px;
  right: 0.7em;
  z-index: 3;
  right: -24px;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

function Header({
  warehouseOptions,
  warehouseLoading,
  warehouseId,
  setWarehouseId,
}) {
  return (
    <HeaderBar className="row">
      <div className="col-8 pt-2 d-flex align-items-center">
        <PageTitle className="d-flex align-items-center">
          <CalendarX size={25} />
          <span className="ml-2">Expirations</span>
        </PageTitle>
        <div className="d-flex align-items-center">
          <PageTitle className="mx-1" />
          <StyledDropdown
            inline
            options={warehouseOptions}
            value={warehouseId}
            loading={warehouseLoading}
            onChange={(e, { value }) => setWarehouseId(value)}
            icon={<DropdownIcon size={18} />}
          />
        </div>
      </div>
    </HeaderBar>
  );
}

export function ExpirationListScreen() {
  const [adjustmentItem, setAdjusmentItem] = useState(null);
  const [warehouseId, setWarehouseId] = useState('');

  const { data: warehouseData, loading: warehouseLoading } =
    WarehouseListHook();

  const warehouseOptions = useMemo(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      return warehouseData?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [warehouseData]);

  const openAdjustmentPopup = item => {
    setAdjusmentItem(item);
  };

  const closeAdjustmentPopup = () => {
    setAdjusmentItem(null);
  };

  const {
    data,
    loading,
    totalData,
    totalPages,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = InventoryListHook(warehouseId, true);

  const columns = [
    {
      text: 'Product Description',
      key: 'description',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Location',
      key: 'location',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Expiry Date',
      key: 'expiry_date',
      width: 2,
      textAlign: 'center',
    },
    {
      text: 'Units Expiring',
      key: 'units_expiring',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Lots',
      key: 'lots',
      width: 2,
      textAlign: 'left',
    },
    {
      text: '',
      key: 'action',
      width: 3,
      textAlign: 'left',
    },
  ];

  useEffect(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      setWarehouseId(warehouseData[0]._id);
    }
  }, [warehouseData]);

  return (
    <BodyContainer>
      <Header
        setWarehouseId={setWarehouseId}
        warehouseId={warehouseId}
        warehouseOptions={warehouseOptions}
        warehouseLoading={warehouseLoading}
      />
      <AppTable
        columns={columns}
        data={data}
        loading={loading}
        totalData={totalData}
        totalPages={totalPages}
        RowComponent={
          <InventoryExpirationRow openPopup={openAdjustmentPopup} />
        }
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
      />
      <InventoryExpirationPopup
        onClose={closeAdjustmentPopup}
        open={!!adjustmentItem}
        item={adjustmentItem}
        refresh={refresh}
        warehouseId={warehouseId}
      />
    </BodyContainer>
  );
}
