import { useNavigate } from 'react-router-dom';
import { CaretLeft } from '@phosphor-icons/react';
import { HoverBorderButton } from '../elements/Button';

export function BackButton() {
  const navgiate = useNavigate();
  const moveBack = () => {
    navgiate(-1);
  };
  return (
    <HoverBorderButton
      className="icon-btn mr-3"
      onClick={moveBack}
      type="button"
    >
      <CaretLeft size={20} />
    </HoverBorderButton>
  );
}
