import { TableCell, TableRow } from 'semantic-ui-react';
import { useMemo } from 'react';
import { Check, Circle, Printer } from '@phosphor-icons/react';
import styled from 'styled-components';
import { CommonUtility, DateFormat, DateUtility } from '../../../utility';
import { ItemInfoPopup } from '../../order';
import { HoverBorderButton, OrangePill, StyledButton } from '../../../elements';

const OrangeStatus = styled(OrangePill)`
  height: 24px;
  padding: 0 10px;
`;

export function SortedToteRow({ item, fulfillTote }) {
  const formattedItems = useMemo(
    () =>
      item.contents?.map(item => ({
        ...(item.product || {}),
        title: item.product?.name,
        qty: item.quantity,
        pImages: {
          displayImage: item.product?.image,
        },
      })) || [],
    [item],
  );

  const totalItems = useMemo(() => {
    let qty = 0;
    item.contents?.forEach(item => {
      qty += item.quantity;
    });
    return qty;
  }, [item]);

  const label = item?.shipping?.labels?.[0];

  return (
    <TableRow>
      <TableCell>{item.toteNumber}</TableCell>
      <TableCell>
        <OrangeStatus>
          <Circle weight="fill" />
          {CommonUtility.toTitleCase(item.status)}
        </OrangeStatus>
      </TableCell>
      <TableCell>
        {DateUtility.formatDate(item.updatedAt, DateFormat.dateTime)}
      </TableCell>
      <TableCell>
        <ItemInfoPopup
          showPrice={false}
          total_items={totalItems}
          items={formattedItems}
        />
      </TableCell>
      <TableCell>
        <div className="d-flex justify-content-end flex-wrap">
          {label && (
            <a download href={label?.url} target="_blank" rel="noreferrer">
              <HoverBorderButton className="flex-btn text-nowrap">
                <Printer className="mr-2 mb-1" size={17} /> Print Label
              </HoverBorderButton>
            </a>
          )}
          {item.status === 'ready_to_pack' && (
            <StyledButton
              onClick={() => fulfillTote(item)}
              className="ml-2 flex-btn text-nowrap"
            >
              <Check className="mr-2 mb-1" size={17} /> Mark Fulfilled
            </StyledButton>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
