import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'semantic-ui-react';
import { useState } from 'react';
import { SkupremeModal } from '../../components';
import {
  ControlledPhoneFormField,
  ControlledTextFormField,
  DropdownFormField,
  HoverBorderButton,
  StyledButton,
} from '../../elements';
import { countryListHook } from '../../hooks';
import { WarehouseService } from '../../utility/services/warehouse';
import { CommonUtility, ToastMessage } from '../../utility';

const WarehouseSchema = yup.object().shape({
  name: yup.string().trim().required('Company Name is Required.'),
  phone: yup.string(),
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('Email is Required'),
  addressLineOne: yup.string().trim().required('Address Line 1 is required'),
  addressLineTwo: yup.string().trim(),
  city: yup.string().trim().required('City is required'),
  state: yup.string().trim().required('State is required'),
  countryCode: yup.string().required('Country is required'),
  zipCode: yup.string().trim().required('Zip Code is required'),
});

export function AddWarehousePopup({ open, onClose, onCreateWarehouse }) {
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(WarehouseSchema),
    shouldUnregister: true,
  });

  const [loading, setLoading] = useState(false);
  const { data: countryData, loading: countryLoading } = countryListHook();

  const onSubmit = async formValues => {
    try {
      setLoading(true);
      let payload = {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
        address: {
          addressLineOne: formValues.addressLineOne,
          addressLineTwo: formValues.addressLineTwo,
          city: formValues.city,
          state: formValues.state,
          zipCode: formValues.zipCode,
          countryCode: formValues.countryCode,
        },
      };
      payload = CommonUtility.removeEmptyFields(payload);
      const res = await WarehouseService.add(payload);
      ToastMessage.success('Warehouse created successfully');
      onCreateWarehouse(res._id);
      onClose();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      size="tiny"
      title="Create Warehouse"
      open={open}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal" className="mb-1">
          <ControlledTextFormField
            error={errors.name}
            hint={errors.name?.message}
            label="Warehouse Name"
            name="name"
            control={control}
            required
          />
          <ControlledTextFormField
            error={errors.email}
            hint={errors.email?.message}
            label="Email Address"
            name="email"
            control={control}
            required
          />
        </Form.Group>
        <ControlledPhoneFormField
          error={errors.phone}
          hint={errors.phone?.message}
          label="Phone"
          name="phone"
          control={control}
        />
        <Form.Group widths="equal">
          <ControlledTextFormField
            label="Address 1"
            name="addressLineOne"
            control={control}
            error={errors.addressLineOne}
            required
            hint={errors.addressLineOne?.message}
          />
          <ControlledTextFormField
            label="Address 2"
            name="addressLineTwo"
            control={control}
            error={errors.addressLineTwo}
            hint={errors.addressLineTwo?.message}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <ControlledTextFormField
            label="City"
            name="city"
            control={control}
            required
            error={errors.city}
            hint={errors.city?.message}
          />
          <ControlledTextFormField
            label="State"
            name="state"
            control={control}
            error={errors.state}
            required
            hint={errors.state?.message}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <ControlledTextFormField
            label="Zip Code"
            required
            name="zipCode"
            control={control}
            error={errors.zipCode}
            hint={errors.zipCode?.message}
          />
          <DropdownFormField
            options={countryData}
            loading={countryLoading}
            name="countryCode"
            label="Country"
            required
            control={control}
            defaultValue=""
            error={errors.countryCode}
            hint={errors.countryCode?.message}
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
          <HoverBorderButton onClick={onClose} disabled={loading} type="button">
            Cancel
          </HoverBorderButton>
          <StyledButton loading={loading} className="ml-2" type="submit">
            Create
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
