export class RandomUtility {
  static generateDarkColor() {
    // Function to generate a random integer between two values
    const getRandomInt = (min, max) =>
      Math.floor(Math.random() * (max - min + 1)) + min;

    // Generate a random RGB color
    let r = getRandomInt(0, 255);
    let g = getRandomInt(0, 255);
    let b = getRandomInt(0, 255);

    // Calculate relative luminance using the formula:
    // Luminance = 0.2126*R + 0.7152*G + 0.0722*B
    let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // If the luminance is too high (too light for white background), generate a new color
    while (luminance > 180) {
      r = getRandomInt(0, 255);
      g = getRandomInt(0, 255);
      b = getRandomInt(0, 255);
      luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }

    // Return the color in hexadecimal format
    return `rgb(${r}, ${g}, ${b})`;
  }
}
