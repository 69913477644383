import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useMemo, useState } from 'react';
import { SkupremeModal } from '../../components';
import {
  CenterDropdownStyle,
  CheckboxFormField,
  DropdownFormField,
  HoverBorderButton,
  MuteText,
  NumberFormField,
  StyledButton,
} from '../../elements';
import {
  CommonUtility,
  decimalMask,
  ToastMessage,
  ZoneService,
} from '../../utility';
import { RackPresetsHook, RackTypesHook } from '../../hooks';
import { useWarehouseDetails } from '../../screens/app/settings/warehouse';

const ZoneSchema = yup.object().shape({
  type: yup.string().required('Type is required'),
  quantity: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
  createPreset: yup.boolean(),
});

export function CreateRackPopup({ onClose, zoneId, open, refreshData, aisle }) {
  const [loading, setLoading] = useState(false);
  const { data, loading: optionsLoading } = RackTypesHook();
  const { warehouseData } = useWarehouseDetails();
  const { data: presetOptions, loading: presetLoading } = RackPresetsHook();
  const [preset, setPreset] = useState('');

  useEffect(() => {
    setPreset('-1');
  }, [open]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(ZoneSchema),
    shouldUnregister: true,
  });

  const onSubmit = async formData => {
    if (!warehouseData?.dimensions?.measurementUnit) {
      ToastMessage.error('No Measurement Unit found for warehouse');
      return;
    }
    try {
      setLoading(true);
      const payload = {
        type: formData.type,
        quantity: formData.quantity,
        dimensions: {
          length: formData.length,
          width: formData.width,
          measurementUnit: warehouseData.dimensions.measurementUnit,
        },
      };

      if (preset !== '-1') {
        payload.presetId = preset;
      }

      if (aisle?._id) {
        payload.aisleId = aisle._id;
      }

      await ZoneService.addRacks(zoneId, payload);
      ToastMessage.success('Racks successfully created.');
      onClose();
      refreshData();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const isCreateNewRack = useMemo(() => preset === '-1', [preset]);

  useEffect(() => {
    if (CommonUtility.isValidArray(presetOptions)) {
      const option = presetOptions.find(x => x.value === preset)?.item;
      if (option) {
        reset({
          type: option.type,
          length: option.dimensions.length,
          width: option.dimensions.width,
        });
      } else {
        reset({
          type: '',
          length: 0,
          width: 0,
        });
      }
    }
  }, [preset]);

  return (
    <SkupremeModal
      size="mini"
      title={aisle?.code ? `Create Rack in ${aisle.code}` : 'Create Rack'}
      open={open}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <MuteText>Rack Preset</MuteText>
          <CenterDropdownStyle
            options={presetOptions}
            selection
            value={preset}
            onChange={(e, { value }) => setPreset(value)}
            placeholder="Preset"
            className="mute-dropdown"
            loading={presetLoading}
          />
        </div>
        <DropdownFormField
          control={control}
          name="type"
          label="Type"
          error={errors.type}
          hint={errors.type?.message}
          loading={optionsLoading}
          options={data}
          selection
          disabled={!isCreateNewRack}
        />
        <NumberFormField
          control={control}
          name="quantity"
          label="Quantity"
          error={errors.quantity}
          hint={errors.quantity?.message}
        />
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            name="length"
            label="Length"
            maskOptions={decimalMask}
            error={errors.length}
            hint={errors.length?.message}
            disabled={!isCreateNewRack}
          />
          <NumberFormField
            control={control}
            name="width"
            label="Width"
            maskOptions={decimalMask}
            error={errors.width}
            hint={errors.width?.message}
            disabled={!isCreateNewRack}
          />
        </FormGroup>
        {isCreateNewRack && (
          <div className="d-flex">
            <CheckboxFormField
              name="createPreset"
              control={control}
              error={errors.createPreset}
              hint={errors.createPreset?.message}
            />
            <span>Save this Rack as a Preset</span>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            Create
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
