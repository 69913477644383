import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'semantic-ui-react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ArrowRight, PencilSimple } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { LoaderBar } from '../../components';
import { countryListHook } from '../../hooks';
import {
  ShadowBox,
  BodyContainer,
  ControlledPhoneFormField,
  ControlledTextFormField,
  DropdownFormField,
  HoverBorderButton,
  MuteText,
  DangerText,
  HeaderBar,
  PageTitle,
  StyledButton,
  ImageContainer,
  ImagePreview,
  HiddenFileAccepter,
} from '../../elements';
import { OrganizationsService } from '../../utility/services';
import { UploadService } from '../../utility/services/upload';
import {
  BYTES_FOR_ONE_MB,
  CommonConstant,
  initialImageCrop,
} from '../../utility/constants';
import { CommonUtility } from '../../utility/common';
import { ToastMessage } from '../../utility';
import { ImageCropModal } from '../../components/ImageCropModal';
import { useAuth } from '../../contexts/auth';

function Header() {
  return (
    <HeaderBar className="row no-sidebar">
      <div className="col-6 pt-2">
        <PageTitle>Company & Warehouse Set-Up</PageTitle>
      </div>
      <div className="col-6 d-flex justify-content-end">
        <StyledButton type="submit" className="flex-btn">
          Next <ArrowRight className="ml-2" />
        </StyledButton>
      </div>
    </HeaderBar>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
`;

const PageContainer = styled.div`
  display: flex;
  padding: 20px 0;
  column-gap: 30px;
  justify-content: space-between;
  max-width: 1400px;
`;

const OrganizationSettingsSchema = yup.object().shape({
  company_name: yup.string().trim().required('Company Name is Required.'),
  phone: yup.string(),
  email: yup.string().trim().email(),
  address_line_one: yup.string().trim(),
  address_line_two: yup.string().trim(),
  city: yup.string().trim(),
  state: yup.string().trim(),
  country: yup.string(),
  zip_code: yup.string().trim(),

  warehouse_name: yup.string().trim().required('Warehouse Name is Required.'),
  warehouse_phone: yup.string(),
  warehouse_email: yup
    .string()
    .trim()
    .email()
    .required('Email Address is Required.'),
  warehouse_address_line_one: yup
    .string()
    .required('Address is Required.')
    .trim(),
  warehouse_address_line_two: yup.string().trim(),
  warehouse_city: yup.string().trim(),
  warehouse_state: yup.string().trim(),
  warehouse_country: yup.string(),
  warehouse_zip_code: yup.string().trim(),
});

const DetailContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  max-width: 320px;
  min-width: 270px;
`;

function WarehouseCreationInfo() {
  return (
    <DetailContainer>
      <h3>SKUPREME allows you to manage one or multiple warehouses</h3>
      <p>
        Each warehouse gets its dedicated workspace while a wholistic overview
        of all warehouses is available through the Summary View.
      </p>
      <p>
        To begin, simply enter the company details and start configuring your
        first warehouse. If you have multiple, you can add them after the
        initial one is set up.
      </p>
    </DetailContainer>
  );
}

export function WarehouseSetup() {
  const { data: countryData, loading: countryLoading } = countryListHook();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState();
  const [fileError, setFileError] = useState('');
  const [files, setFiles] = useState([]);
  const { user, refreshUserData } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(OrganizationSettingsSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (user?.email) {
      reset({
        email: user?.email,
        country: 'US',
        warehouse_country: 'US',
      });
    } else {
      reset({
        country: 'US',
        warehouse_country: 'US',
      });
    }
  }, [user]);

  const onCropModalConfirm = files => {
    setFiles(files);
    setImage(files[0]);
  };

  const onSubmit = async formData => {
    setLoading(true);

    let data = {
      name: formData.company_name,
      email: formData.email,
      phone: formData.phone,
      logo: {
        key: null,
        bucketName: null,
      },
      address: {
        addressLineOne: formData.address_line_one,
        addressLineTwo: formData.address_line_two,
        city: formData.city,
        state: formData.state,
        zipCode: formData.zip_code,
        countryCode: formData.country,
      },
      warehouse: {
        name: formData.warehouse_name,
        email: formData.warehouse_email,
        phone: formData.warehouse_phone,
        address: {
          addressLineOne: formData.warehouse_address_line_one,
          addressLineTwo: formData.warehouse_address_line_two,
          city: formData.warehouse_city,
          state: formData.warehouse_state,
          zipCode: formData.warehouse_zip_code,
          countryCode: formData.warehouse_country,
        },
      },
    };

    if (CommonUtility.isValidArray(files)) {
      const { key, bucket_name } = await upload();
      data.logo.key = key;
      data.logo.bucketName = bucket_name;
    } else {
      delete data.logo;
    }

    try {
      data = CommonUtility.removeEmptyFields(data);
      const res = await OrganizationsService.add(data);
      ToastMessage.success('Organization Created Successfully');
      const userDetails = user;
      userDetails.organizations = [...userDetails.organizations, res];

      await refreshUserData();
      setTimeout(() => {
        navigate(`/app/settings/warehouse/${res?.warehouse?._id}`);
      }, 100);
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const upload = async () => {
    const newFiles = files;
    try {
      if (newFiles.length > 0) {
        const file = newFiles[0];
        const { uploadUrl, key, bucket, downloadUrl } =
          await OrganizationsService.logoSignedUrl({
            type: 'image',
            extension: file.name?.split('.').pop(),
          });
        await UploadService.media(uploadUrl, file, file.type);
        return {
          key,
          bucket_name: bucket,
          downloadUrl,
        };
      }
    } catch (error) {
      return {
        key: null,
        bucket_name: null,
      };
    }
  };

  const fileRef = useRef(null);
  const acceptFile = () => {
    fileRef?.current?.click();
  };

  const handleFileChange = event => {
    const acceptedFiles = event.target.files;
    if (!acceptedFiles?.length) {
      return;
    }

    const maxSize = 2 * BYTES_FOR_ONE_MB;
    if (acceptedFiles[0].size > maxSize) {
      ToastMessage.error(
        `File size should be less than ${maxSize / BYTES_FOR_ONE_MB} MB.`,
      );
      return;
    }

    setImage(acceptedFiles[0]);
    setIsModalOpen(true);
    setFileError('');
  };

  return (
    <BodyContainer>
      {loading && <LoaderBar />}
      <PageContainer>
        <WarehouseCreationInfo />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Header />
          <ShadowBox>
            <h3>Company Details</h3>
            <Form.Group widths="equal">
              <ControlledTextFormField
                error={errors.company_name}
                hint={errors.company_name?.message}
                label="Organization Name"
                name="company_name"
                control={control}
                required
              />
              <Form.Field>
                <MuteText>Profile Picture</MuteText>
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <ImageContainer>
                      <ImagePreview
                        src={
                          (image && URL.createObjectURL(image)) ||
                          CommonConstant.defaultImage
                        }
                      />
                    </ImageContainer>
                    {fileError && (
                      <DangerText className="mt-2">{fileError}</DangerText>
                    )}
                  </div>
                  <HiddenFileAccepter
                    onChange={handleFileChange}
                    accept="image/*"
                    ref={fileRef}
                    type="file"
                  />
                  <HoverBorderButton
                    type="button"
                    onClick={acceptFile}
                    className="p-2 mx-2"
                  >
                    <PencilSimple size={18} />
                  </HoverBorderButton>
                </div>
                <ImageCropModal
                  fileName={image?.name}
                  src={image && URL.createObjectURL(image)}
                  altText="Profile pic"
                  upload={onCropModalConfirm}
                  open={isModalOpen}
                  setOpen={setIsModalOpen}
                  initialCrop={initialImageCrop}
                  aspect={1}
                />
              </Form.Field>
            </Form.Group>

            <ControlledPhoneFormField
              error={errors.phone}
              hint={errors.phone?.message}
              label="Phone"
              name="phone"
              control={control}
              required
            />
            <ControlledTextFormField
              error={errors.email}
              hint={errors.email?.message}
              label="Email Address"
              name="email"
              control={control}
              required
            />
            <Form.Group widths="equal">
              <ControlledTextFormField
                label="Address 1"
                name="address_line_one"
                control={control}
                error={errors.address_line_one}
                hint={errors.address_line_one?.message}
              />
              <ControlledTextFormField
                label="Address 2"
                name="address_line_two"
                control={control}
                error={errors.address_line_two}
                hint={errors.address_line_two?.message}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <ControlledTextFormField
                label="City"
                name="city"
                control={control}
                error={errors.city}
                hint={errors.city?.message}
              />
              <ControlledTextFormField
                label="State"
                name="state"
                control={control}
                error={errors.state}
                hint={errors.state?.message}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <ControlledTextFormField
                label="Zip Code"
                name="zip_code"
                control={control}
                error={errors.zip_code}
                hint={errors.zip_code?.message}
              />
              <DropdownFormField
                options={countryData}
                loading={countryLoading}
                name="country"
                label="Country"
                control={control}
                defaultValue=""
                error={errors.country}
                hint={errors.country?.message}
              />
            </Form.Group>
          </ShadowBox>
          <ShadowBox>
            <h3>Warehouse Details</h3>
            <Form.Group widths="equal">
              <ControlledTextFormField
                error={errors.warehouse_name}
                hint={errors.warehouse_name?.message}
                label="Warehouse Name"
                name="warehouse_name"
                control={control}
                required
              />
            </Form.Group>

            <ControlledPhoneFormField
              error={errors.warehouse_phone}
              hint={errors.warehouse_phone?.message}
              label="Phone"
              name="warehouse_phone"
              control={control}
              required
            />
            <ControlledTextFormField
              error={errors.warehouse_email}
              hint={errors.warehouse_email?.message}
              label="Email Address"
              name="warehouse_email"
              control={control}
              required
            />
            <Form.Group widths="equal">
              <ControlledTextFormField
                label="Address 1"
                name="warehouse_address_line_one"
                control={control}
                error={errors.warehouse_address_line_one}
                hint={errors.warehouse_address_line_one?.message}
              />
              <ControlledTextFormField
                label="Address 2"
                name="warehouse_address_line_two"
                control={control}
                error={errors.warehouse_address_line_two}
                hint={errors.warehouse_address_line_two?.message}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <ControlledTextFormField
                label="City"
                name="warehouse_city"
                control={control}
                error={errors.warehouse_city}
                hint={errors.warehouse_city?.message}
              />
              <ControlledTextFormField
                label="State"
                name="warehouse_state"
                control={control}
                error={errors.warehouse_state}
                hint={errors.warehouse_state?.message}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <ControlledTextFormField
                label="Zip Code"
                control={control}
                error={errors.warehouse_zip_code}
                name="warehouse_zip_code"
                hint={errors.warehouse_zip_code?.message}
              />
              <DropdownFormField
                options={countryData}
                loading={countryLoading}
                name="warehouse_country"
                label="Country"
                control={control}
                defaultValue=""
                error={errors.warehouse_country}
                hint={errors.warehouse_country?.message}
              />
            </Form.Group>
          </ShadowBox>
        </StyledForm>
      </PageContainer>
    </BodyContainer>
  );
}
