import { Plus, Warehouse } from '@phosphor-icons/react';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppTable, FilterButton, FilterPill } from '../../../../components';
import {
  BodyContainer,
  HeaderBar,
  HoverBorderButton,
  MuteText,
  PageTitle,
} from '../../../../elements';
import {
  FilterHook,
  useDebouncedMemo,
  WarehouseListHook,
  WaveRuleListHook,
} from '../../../../hooks';
import { WaveRuleRow } from '../../../../page-components/waves/WaveRuleRow';
import { AddWaveRulePopup } from '../../../../page-components/waves';
import { CommonUtility } from '../../../../utility';

const FilterPillContainer = styled.div`
  width: 100%;
  margin-top: 5px;
`;

function Header({ openPopup, filters, finalFilter, loading, updateAllFilter }) {
  const {
    finalValue,
    setFinalValue,
    activeIndex,
    setActiveIndex,
    open,
    setOpen,
    pillFilters,
    removeFilter,
    openRelevantFilter,
  } = FilterHook(filters, finalFilter);

  return (
    <>
      <HeaderBar className="row">
        <div className="col-6 pt-2">
          <PageTitle>Wave Rules</PageTitle>
        </div>
        <div className="col-6 row justify-content-end">
          <FilterButton
            setFinalFilter={updateAllFilter}
            loading={loading}
            filters={filters}
            finalFilter={finalFilter}
            setFinalvalue={setFinalValue}
            finalValue={finalValue}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            open={open}
            setOpen={setOpen}
          />
          <HoverBorderButton onClick={openPopup} className="flex-btn">
            <Plus className="mr-1" />
            Wave Rule
          </HoverBorderButton>
        </div>
      </HeaderBar>
      <FilterPillContainer>
        {pillFilters?.length > 0 && (
          <div className="d-flex align-items-center justify-content-end">
            <MuteText>Filters</MuteText>
            {filters.map(filter => (
              <FilterPill
                data={filter}
                userValue={finalValue}
                removeFilter={removeFilter}
                openRelevantFilter={openRelevantFilter}
              />
            ))}
          </div>
        )}
      </FilterPillContainer>
    </>
  );
}

export function WaveRulesScreen() {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
    refresh,
    updateAllFilter,
  } = WaveRuleListHook();

  const [addRulePopup, setAddRulePopup] = useState(false);
  const { data: warehouseList, loading: warehouseLoading } =
    WarehouseListHook();
  const [searchParams] = useSearchParams();

  const warehouseOptions = useMemo(
    () =>
      warehouseList?.map(warehouse => ({
        value: warehouse._id,
        text: warehouse.name,
      })),
    [warehouseList],
  );

  const columns = [
    {
      text: 'Name',
      key: 'name',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Created',
      key: 'created',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Details',
      key: 'status',
      textAlign: 'left',
      width: 3,
    },
    {
      text: 'Threshold',
      textAlign: 'left',
      key: 'details',
      width: 3,
    },
  ];

  const openPopup = () => {
    setAddRulePopup(true);
  };
  const closePopup = () => {
    setAddRulePopup(false);
  };

  const filters = useDebouncedMemo(
    () => {
      const query = Object.fromEntries(searchParams.entries());
      const temp = { ...query };
      Object.keys(temp).forEach(key => {
        const final = CommonUtility.tryParseJSONObject(temp[key]);
        if (final) {
          temp[key] = final;
        }
      });

      const defaultValues = {
        ...temp,
      };

      const arr = [
        {
          name: 'warehouseId',
          label: 'Warehouse',
          icon: <Warehouse />,
          options: warehouseOptions,
          multiple: true,
          search: true,
          defaultValue: (defaultValues && defaultValues.warehouseId) || null,
        },
        {
          skip: true,
          name: 'page',
          must: true,
          defaultValue: (defaultValues && defaultValues.page) || 1,
          ignoreDefault: 1,
        },
        {
          skip: true,
          name: 'limit',
          must: true,
          defaultValue:
            defaultValues && CommonUtility.isValidNumber(defaultValues.limit)
              ? Number(defaultValues.limit)
              : 10,
          ignoreDefault: 10,
        },
      ];

      return arr;
    },
    [warehouseOptions],
    500,
  );

  const editRule = rule => {
    setAddRulePopup(rule);
  };

  return (
    <BodyContainer>
      <Header
        filters={filters}
        finalFilter={filter}
        updateAllFilter={updateAllFilter}
        loading={loading}
        openPopup={openPopup}
      />
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={<WaveRuleRow editRule={editRule} />}
      />
      <AddWaveRulePopup
        onClose={closePopup}
        open={addRulePopup}
        item={addRulePopup}
        refresh={refresh}
        warehouseOptions={warehouseOptions}
        warehouseLoading={warehouseLoading}
      />
    </BodyContainer>
  );
}
