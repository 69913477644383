import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { MapPin, RectangleDashed } from '@phosphor-icons/react';
import { useState } from 'react';
import { ItemImage, OrganisationAvatar } from '../../components';
import { BoldText, ColoredPill, MuteText, StyledButton } from '../../elements';
import { ExpandCollapseToggle } from '../shipments/Items/common';
import { DateFormat, DateUtility } from '../../utility';

const ActionRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

const ItemTitle = styled.div`
  .title {
    max-width: 15vw;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ActionsButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 7px;
  row-gap: 7px;
  justify-content: end;
`;

export function InventoryExpirationRow({ item, openPopup }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ActionRow>
        <TableCell>
          <div className="d-flex">
            <ItemImage
              imageKey="p_images"
              displayImageKey="display_image"
              item={item.product}
            />
            <ItemTitle className="ml-4">
              <div className="title">{item.product.p_title}</div>
              <div className="d-flex">
                <MuteText className="mr-2">SKU</MuteText>
                <BoldText>{item.product.p_sku}</BoldText>
              </div>
              <div className="d-flex mt-2">
                <OrganisationAvatar
                  name={item?.customerOrganization?.company_name}
                  image={item?.customerOrganization?.logo?.url}
                  mini
                />
                {item?.customerOrganization?.company_name}
              </div>
            </ItemTitle>
          </div>
        </TableCell>
        <TableCell textAlign="left">
          {item.locationSummary?.length === 1 && (
            <div>
              <ColoredPill color={item.locationSummary[0].zone.color}>
                <RectangleDashed /> {item.locationSummary[0].zone.code}
              </ColoredPill>
              <ColoredPill
                className="mt-1"
                color={item.locationSummary[0].zone.color}
              >
                <MapPin />
                {item.locationSummary[0]?.location.code}
              </ColoredPill>
            </div>
          )}
          {item.locationSummary?.length > 1 && (
            <MuteText>Multiple Locations</MuteText>
          )}
        </TableCell>
        <TableCell />
        <TableCell textAlign="left">{item.quantity.total}</TableCell>

        <TableCell>
          <ExpandCollapseToggle
            collapseText="Collapse Lots"
            expandText="Expand Lots"
            title="Lots"
            open={open}
            setOpen={setOpen}
          />
        </TableCell>
        <TableCell textAlign="center">
          <ActionsButtons>
            <StyledButton
              onClick={() => openPopup({ ...item, disposeLot: true })}
            >
              Dispose
            </StyledButton>
            <StyledButton
              onClick={() => openPopup({ ...item, transferLot: true })}
            >
              Transfer
            </StyledButton>
          </ActionsButtons>
        </TableCell>
      </ActionRow>
      {open &&
        item.locationSummary?.map((locationSummary, index) => (
          <LotRow
            item={locationSummary}
            openPopup={() =>
              openPopup({ ...item, lotIndex: index, disposeLot: true })
            }
            transferLot={() =>
              openPopup({ ...item, lotIndex: index, transferLot: true })
            }
          />
        ))}
    </>
  );
}

const BoxShadowTableRow = styled(TableRow)`
  background: ${({ theme }) => theme.colors.box};
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

function LotRow({ item, openPopup, transferLot }) {
  return (
    <BoxShadowTableRow textAlign="center">
      <TableCell textAlign="left">
        <div>
          <MuteText>Lot #</MuteText>
          <span className="ml-2">{item.lotNumber}</span>
        </div>
      </TableCell>
      <TableCell>
        <div>
          <ColoredPill color={item.zone.color}>
            <RectangleDashed /> {item.zone.code}
          </ColoredPill>
          <ColoredPill className="mt-1" color={item.zone.color}>
            <MapPin />
            {item?.location.code}
          </ColoredPill>
        </div>
      </TableCell>
      <TableCell>
        <div className="d-flex flex-column">
          <span>
            {DateUtility.formatDate(item.expiryDate, DateFormat.barChartMonth)}
          </span>
          <MuteText>
            {DateUtility.hourMinuteTime(item.expiryDate, null, 1)} Ago
          </MuteText>
        </div>
      </TableCell>
      <TableCell textAlign="left">{item.quantity.total}</TableCell>
      <TableCell />
      <TableCell>
        <ActionsButtons>
          <StyledButton onClick={openPopup}>Dispose</StyledButton>
          <StyledButton onClick={transferLot}>Transfer</StyledButton>
        </ActionsButtons>
      </TableCell>
    </BoxShadowTableRow>
  );
}
