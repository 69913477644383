import styled from 'styled-components';
import {
  CaretDown,
  GridNine,
  Package,
  Plus,
  Tray,
} from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { RackDetailHook } from '../../../../../hooks';
import { MuteText, ShadowBox } from '../../../../../elements';
import { CommonUtility } from '../../../../../utility';
import {
  AddBayPopup,
  EditBayPopup,
  RackDimensionsDetails,
} from '../../../../../page-components';

const LocationRenderer = styled.div`
  padding: 10px 25px;
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  border-bottom: 15px solid #dbdbdf;
  .name {
    position: absolute;
    bottom: -3px;
    left: 50%;
    font-size: 12px;
    transform: translate(-50%);
    z-index: 10;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 94px;
  width: 120px;
  background: ${({ theme }) => theme.colors.box};
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  color: black;
  z-index: 2;

  .code {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
`;

const LevelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 50px;
`;

const LeftStand = styled.div`
  position: absolute;
  height: calc(100% + 50px);
  top: 0px;
  width: 13px;
  left: -2px;
  background: #dbdbdf;
  border-radius: 3px;
`;

const RightStand = styled.div`
  position: absolute;
  background: #dbdbdf;
  height: calc(100% + 50px);
  top: 0px;
  width: 13px;
  border-radius: 3px;
  right: -2px;
`;

function AddBay(props) {
  return (
    <LocationContainer {...props}>
      <div className="d-flex align-items-center justify-content-center">
        <Plus /> Bay
      </div>
    </LocationContainer>
  );
}

const StyledDropdown = styled(Dropdown)`
  font-size: 20px;
  margin-right: 30px;
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0px;
  right: 0.7em;
  z-index: 3;
  right: -24px;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 998;
  height: 67px;
  padding: 14px 5px;
  display: flex;
  align-items: center;
`;

function Header({ rackId, rackOptions, onChangeRack }) {
  return (
    <HeaderContainer>
      <GridNine className="mr-2" size={22} />
      <StyledDropdown
        inline
        options={rackOptions}
        value={rackId}
        icon={<DropdownIcon size={18} />}
        onChange={(e, { value }) => onChangeRack(value)}
      />
    </HeaderContainer>
  );
}

const RelativeContainer = styled.div`
  position: relative;
`;

const AddLevelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  border: 2px dashed ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.box};
  }
`;

export function RackDetails({ rackId, rackOptions, onChangeRack }) {
  const { data, locationData, refreshData, loading } = RackDetailHook(rackId);
  const [bayPopup, setBayPopup] = useState(false);
  const [levelsData, setLevelsData] = useState([]);
  const [location, setLocation] = useState(null);

  const openLocationPopup = loc => {
    setLocation(loc);
  };

  const closeLocationPopup = () => {
    setLocation(null);
  };

  useEffect(() => {
    if (CommonUtility.isValidArray(locationData)) {
      setLevelsData(locationData.reverse());
    }
  }, [locationData]);

  const createNewBay = (level, bay) => {
    setBayPopup({
      level,
      bay,
    });
  };

  const closePopup = () => {
    setBayPopup(null);
  };

  const addLevel = () => {
    let levels = [...levelsData];
    levels.unshift({
      level: levelsData[0]?.level + 1,
    });

    setLevelsData(levels);
  };

  return (
    <>
      <Header
        rackOptions={rackOptions}
        onChangeRack={onChangeRack}
        rackId={rackId}
      />
      <div className="row mt-2">
        <ShadowBox className="col-8">
          <LevelsContainer>
            <RelativeContainer>
              <LocationRenderer>
                <AddLevelContainer onClick={addLevel}>
                  <Plus className="mr-1" /> Level
                </AddLevelContainer>
              </LocationRenderer>
            </RelativeContainer>
            {levelsData?.map(location => (
              <RelativeContainer key={`level-${location.level}`}>
                <LocationRenderer>
                  <div className="name">Level {location.level}</div>
                  {location.locations?.map(location => (
                    <LocationContainer
                      onClick={() => openLocationPopup(location)}
                      key={location._id}
                    >
                      {location.type === 'pallet' ? (
                        <Package size={20} />
                      ) : (
                        <Tray size={20} />
                      )}

                      {location.type === 'pallet' ? (
                        <span className="my-2">Pallet</span>
                      ) : (
                        <span className="my-2">Bin</span>
                      )}
                      <MuteText className="code">{location?.code}</MuteText>
                    </LocationContainer>
                  ))}
                  <AddBay
                    onClick={() =>
                      createNewBay(
                        location.level,
                        location?.locations?.[location?.locations?.length - 1]
                          ?.coordinates?.bay,
                      )
                    }
                  />
                </LocationRenderer>
              </RelativeContainer>
            ))}
            {CommonUtility.isValidArray(levelsData) && (
              <>
                <LeftStand />
                <RightStand />
              </>
            )}
          </LevelsContainer>
        </ShadowBox>
        <div className="col-4">
          <RackDimensionsDetails detailLoading={loading} data={data} />
        </div>
      </div>
      <AddBayPopup
        refreshData={refreshData}
        open={bayPopup}
        rackId={rackId}
        onClose={closePopup}
      />
      <EditBayPopup
        open={!!location}
        onClose={closeLocationPopup}
        locationData={location}
        refreshData={refreshData}
      />
    </>
  );
}
