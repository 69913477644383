import { Check, Circle, Cube } from '@phosphor-icons/react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';
import {
  ActionEditDots,
  ActionMenuItem,
  BackButton,
} from '../../../components';
import { GreenPill, HeaderBar, PageTitle, WhitePill } from '../../../elements';
import { theme } from '../../../utility';

const Status = styled(GreenPill)`
  height: 24px;
  padding: 0 10px;
`;

const Info = styled(WhitePill)`
  height: 24px;
  padding: 0 10px;
`;

export function OrderDetailsHeader({ data, openPopup, showAction, loading }) {
  return (
    <HeaderBar className="row">
      <div className="col-9 row align-items-center">
        <BackButton />
        {loading ? (
          <Loader active inline />
        ) : (
          <>
            <PageTitle className="d-flex align-items-center">
              <Cube size={25} />
              <span className="ml-2">ID-{data.marketplaceOrderDisplayId}</span>
            </PageTitle>
            <Status className="ml-4">
              <Circle weight="fill" color={theme.colors.green} />
              {data?.states?.[0]}
            </Status>
            <Info className="ml-4">
              <Cube />
              {data?.items?.length} Items
            </Info>
          </>
        )}
      </div>
      <div className="col-3 row justify-content-end">
        {showAction && (
          <ActionEditDots
            showByDefault
            inline
            basic
            preventChangingBg
            on="hover"
            position="bottom left"
          >
            <ActionMenuItem
              onClick={() => {
                openPopup();
              }}
            >
              <Check />
              Fulfill
            </ActionMenuItem>
          </ActionEditDots>
        )}
      </div>
    </HeaderBar>
  );
}
