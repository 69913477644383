import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CaretDown, RectangleDashed } from '@phosphor-icons/react';
import { Dropdown } from 'semantic-ui-react';
import { ZoneDetailHook } from '../../../../hooks/zone';
import {
  CreateAislePopup,
  CreateRackPopup,
  EditAislePopup,
  RackViewer,
  ZoneDimensionsDetails,
} from '../../../../page-components/zones';
import { CommonUtility } from '../../../../utility';
import { RandomUtility } from '../../../../utility/random';

const StyledDropdown = styled(Dropdown)`
  font-size: 20px;
  margin-right: 30px;
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0px;
  right: 0.7em;
  z-index: 3;
  right: -24px;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 998;
  height: 67px;
  padding: 14px 5px;
  display: flex;
  align-items: center;
`;

function Header({ zoneId, zoneOptions, onChangeZone }) {
  return (
    <HeaderContainer>
      <RectangleDashed className="mr-2 mb-1" size={22} />
      <StyledDropdown
        inline
        options={zoneOptions}
        value={zoneId}
        icon={<DropdownIcon size={18} />}
        onChange={(e, { value }) => onChangeZone(value)}
      />
    </HeaderContainer>
  );
}

export function ZoneDetailScreen({
  zoneId,
  zoneOptions,
  onChangeZone,
  data,
  refreshData,
  count,
  aloneRacks,
  loading,
  setData,
  setAloneRacks,
  updatePositions,
}) {
  const [rackPopup, setRackPopup] = useState(false);
  const [aislePopup, setAislePopup] = useState(false);
  const [aisle, setAisle] = useState(null);
  const [hasIsles, setHasIsles] = useState(false);
  const [editAislePopup, setEditAislePopup] = useState(null);

  const { data: ZoneData, refresh: refreshZoneData } = ZoneDetailHook(zoneId);

  useEffect(() => {
    if (CommonUtility.isValidArray(data)) {
      setHasIsles(true);
    }
  }, [data]);

  const toggleAisles = () => {
    setHasIsles(!hasIsles);
  };

  const openAislePopup = () => {
    setAislePopup(true);
  };

  const closeAislePopup = () => {
    setAislePopup(false);
  };

  const closeRackPopup = () => {
    setRackPopup(false);
    setAisle(null);
  };

  const addRackInAsile = aisle => {
    setAisle(aisle);
    setRackPopup(true);
  };

  const onCloseEditAislePopup = () => {
    setEditAislePopup(null);
  };

  const onClickEditAisle = aisle => {
    setEditAislePopup(aisle);
  };

  const color = useMemo(
    () =>
      ZoneData?.color !== 'rgb(255,255,255)'
        ? ZoneData?.color
        : RandomUtility.generateDarkColor(),
    [ZoneData],
  );

  return (
    <>
      <Header
        zoneId={zoneId}
        zoneOptions={zoneOptions}
        onChangeZone={onChangeZone}
      />
      <div className="row">
        <div className="col-8 pl-0">
          <RackViewer
            aloneRacks={aloneRacks}
            data={data}
            addRackInAsile={addRackInAsile}
            aisleView={hasIsles}
            loading={loading}
            onClickEditAisle={onClickEditAisle}
            openAislePopup={openAislePopup}
            setData={setData}
            setAloneRacks={setAloneRacks}
            color={color}
            updatePositions={updatePositions}
          />
        </div>
        <div className="col-4 p-0 mt-2">
          <ZoneDimensionsDetails
            refresh={refreshZoneData}
            detailLoading={loading}
            data={ZoneData}
            hasIsles={hasIsles}
            toggleAisles={toggleAisles}
            count={count}
            color={color}
          />
        </div>
      </div>
      <CreateRackPopup
        zoneId={zoneId}
        open={rackPopup}
        onClose={closeRackPopup}
        refreshData={refreshData}
        aisle={aisle}
      />
      <CreateAislePopup
        zoneId={zoneId}
        open={aislePopup}
        onClose={closeAislePopup}
        refreshData={refreshData}
      />
      <EditAislePopup
        zoneId={zoneId}
        refreshData={refreshData}
        open={editAislePopup}
        onClose={onCloseEditAislePopup}
        aisle={editAislePopup}
      />
    </>
  );
}
