// TODO: Group & Sort
export const APIPath = {
  // login and registration
  WavesRulesList: 'orders/waveRules/list',
  account: 'account',
  customers: 'customers',
  inventory: 'inventory',
  inventoryProposals: 'inventory/proposals',
  inventoryTransfers: 'inventory/transfers',
  inventoryFeed: 'inventory/feeds',
  inventoryFeedUploadUrl: 'inventory/feeds/uploadUrl',
  invites: 'invites',
  login: 'auth/login',
  orderAllocationTote: 'orders/allocations/tote',
  orderTotes: 'orders/totes',
  orderWaveRules: 'orders/waveRules',
  orderWaves: 'orders/waves',
  orderWavesList: 'orders/waves/list',
  orders: 'orders',
  organizationDashboard: 'dashboards',
  organizationIntegration: 'organizations/integration',
  organizations: 'organizations',
  organizationsLogoUrl: 'organizations/upload',
  rackLocations: 'racks/locations',
  rackTypes: '/racks/rack-types',
  racks: 'racks',
  rackPresets: 'racks/rack-presets/list',
  refreshToken: 'auth/token/refresh',
  register: 'auth/register',
  requestEmailCode: 'auth/register/verify-email',
  shipments: 'shipments',
  sortedOrderWavesList: 'orders/allocations/sorted',
  users: 'users',
  verifyLogin: 'auth/login/verify',
  warehouses: 'warehouses',
  zoneTypes: 'zones/types',
  zones: 'zones',
};
